.postventa {
	background: $white-bone;
	padding: 72px 0;
	
	&#contacto {
		background: $grey;
		padding: 72px 0;
		margin: 0;
		align-items: center;
		
		.top {
			margin: 0 0 30px 0;
			text-align: center;
			
			.icon {
				width: 80px;
				height: 72px;
				margin: 0 auto 12px;
			}

			h2 {
				margin: 0 0 16px 0;
				padding: 0;
				border: none;
			}
		}

		form {
			
			input {
				background: $grey;

				&:focus {
					background: $black;
				}
			}

			select {
				color: $black;
				appearance: none;
				background: $grey url('../img/bg-arrow-select.svg') no-repeat;
				background-size: 26px;
				background-position: right 12px center;
				border-radius: 0;
			}
		}

		.interna & {

			.top {
				text-align: left;
				margin: 0 60px 60px;
				padding: 0;

				h2 {
					color: $red;
					position: relative;
					padding: 0 0 16px 0;
					margin-bottom: 32px;
					
					&:after {
						content: "";
						background: $red;
						height: 4px;
						width: 110px;
						position: absolute;
						bottom: 0;
						left: 0;
					}
				}

				ul {
					li {
						margin-bottom: 12px;
		        		padding-left: 56px;
		        		// background-size: 22px 38px;
		        		
		        		a {
		        			color: $black;
		        			font-weight: 700;
		        			
		        			&:hover {
		        				color: $red;
		        			}
		        		}
						
						&.dir {
							background: url('../img/icon-contact-dir.svg') no-repeat center left;
							background-size: 38px 38px;
						}
						&.tel {
							background: url('../img/icon-contact-tel.svg') no-repeat center left;
							background-size: 38px 38px;
						}
						&.mail {
							background: url('../img/icon-contact-mail.svg') no-repeat center left;
							background-size: 38px 38px;
						}
					}
				}				
			}

			form {
				margin: 0 60px;
				
				h2 {
					color: $red;
					font-size: 30px;
					position: relative;
					margin-bottom: 32px;
					padding: 0 0 16px 0;
					
					&:after {
						content: "";
						background: $red;
						height: 4px;
						width: 110px;
						position: absolute;
						bottom: 0;
						left: 0;
					}
				}
			}

			&.consultas {
				
				.top {
					text-align: center;

					h2 {
						border: none;
						margin-bottom: 0;
						
						&:after {
							content: none;
						}
					}
				}
			}
		}
	}
	
	.top {
		padding: 0 30px;
		margin: 0 0 72px 30px;
	
		h2 {
			font-size: 30px;
			margin-left: -30px;
			margin-bottom: 16px;
			padding-left: 30px;
			border-left: 3px $red solid;
			
			span {
				color: $red;
				display: block;
			}

			em {
				text-decoration: none;
				font-size: 22px;
			}
		}

		ul {
			margin-bottom: 22px;
			
			li {
				background: url('../img/bg-tilde.svg') no-repeat center left;
		        background-size: 26px 20px;
		        padding-left: 38px;
		        line-height: 38px;
			}
		}

		a {
			color: $red;
		}
		
	}
	
	form {
		margin: 0 30px;
	}
	
	@include breakpoint(phablet) {

		form {
			margin: 0 130px;
		}
	}		
	@include breakpoint(tablet) {
		display: flex;
		padding: 72px 30px;
		margin: 0;

		&#contacto {
			padding: 72px 30px;
			
			.top {
				padding: 0 30px 0 0;
				
				h2 {
					font-size: 32px;
				}
			}				

			form {
				
				.mcontacto {
					grid-column: 1 / 3;
					grid-row: 3 / 4;
					margin: 0;
				}
				.minteres {
					grid-column: 1 / 3;
					grid-row: 4 / 5; 
					margin: 0;					
				}
				.button__rayen {
					grid-row: 5 / 6; 
				}

				input, textarea, select {
					margin: 0;
				}
			}

			.interna & {

				.top {
					margin: 0;
					
					h2 {
						font-size: 36px;
						margin-bottom: 52px;
					}
				}
				form {
					margin: 0;	
					
					h2 {
						font-size: 36px;
						margin-bottom: 52px;
						grid-column: 1 / 3;
						grid-row: 1 / 2;
					}
					#pilot_firstname_contact, #pilot_firstname_post {
						grid-column: 1 / 3;
						grid-row: 2 / 3;
					}
					#pilot_cellphone_contact, #pilot_cellphone_post {
						grid-column: 1 / 2;
						grid-row: 3 / 4;							
					}
					#pilot_email_contact, #pilot_email_post {
						grid-column: 2 / 3;
						grid-row: 3 / 4;							
					}
					.mcontacto {
						grid-column: 1 / 3;
						grid-row: 4 / 5;
						margin: 0;
					}
					.minteres {
						grid-column: 1 / 3;
						grid-row: 5 / 6; 
						margin: 0;					
					}
					.button__rayen {
						grid-row: 6 / 7; 
					}
				}
			}
		}
		
		.top {
			flex: 40% 0 0;
			margin: 0;
			
			h2 {
				font-size: 42px;
			}
		}

		form {
			flex: 60% 0 0;
			margin: 0;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 10px;
			
			input, textarea { margin: 0;}

			#pilot_firstname_contact, #pilot_firstname_post, #pilot_firstname_interna {
				grid-column: 1 / 3;
				grid-row: 1 / 2; 
			}
			#pilot_cellphone_contact, #pilot_cellphone_post, #pilot_cellphone_interna {
				grid-column: 1 / 2;
				grid-row: 2 / 3; 
			}
			#pilot_email_contact, #pilot_email_post, #pilot_email_interna {
				grid-column: 2 / 3;
				grid-row: 2 / 3; 
			}
			#pilot_notes_contact, #pilot_notes_post, #pilot_notes_interna {
				grid-column: 1 / 3;
				grid-row: 3 / 4; 
			}
			.button__rayen {
				grid-column: 1 / 3;
				grid-row: 4 / 5; 
			}

			// .interna & {
				
			// 	input, textarea { margin: 0;}

			// 	#pilot_notes_contact, #pilot_notes_post, #pilot_notes_interna {
			// 		grid-column: 1 / 3;
			// 		grid-row: 3 / 4;
			// 	}
			// 	.button__rayen {
			// 		grid-column: 1 / 3;
			// 		grid-row: 4 / 5;
			// 	}
			// }
		}
	}

	@include breakpoint(laptop) {		
		padding: 72px 90px;
		justify-content: space-between;

		&#contacto {
			
			.top {
				
				.icon {
					width: 126px;
					height: 116px;
					margin: 0 auto 32px;
				}

				p {
					font-size: 18px;
				}
			}


			.interna & {
				padding: 60px;
				// justify-content: space-between;
				
				.top {
					
					ul li {
						margin-bottom: 36px;
					}
				}

				form {
					margin-top: 80px;
				}
			}
		}
		
		.top {
			
			h2 {
				
				em {
					font-size: 30px;
				}
			}
		}			

		form {
			max-width: 680px;
		}
	}

	@include breakpoint(desktop) {		
		
		&#contacto {
		
			.interna & {
				padding: 60px 100px;
			}
		}
	}
	@include breakpoint(bigdesktop) {		
		padding: 100px 10vw;
		
		&#contacto {
		
			.interna & {
				padding: 100px 10vw;
			}
		}
	}
	
}