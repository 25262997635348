@mixin breakpoint($point) {
	@if $point == bigdesktop {
		@media (min-width: 1600px) { @content ; }
	}
	@if $point == desktop {
		@media (min-width: 1440px) { @content ; }
	}
	@else if $point == laptop {
		@media (min-width: 1200px) { @content ; }
	}
	@else if $point == tablet {
		@media (min-width: 1024px) { @content ; }
	}
	@else if $point == phablet {
		@media (min-width: 768px)  { @content ; }
	}
	@else if $point == mobilelandscape {
		@media (max-width: 767px) and (orientation: landscape) { @content ; }
	}
	@else if $point == mobileonly {
		@media (max-width: 767px)  { @content ; }
	}
	@else if $point == bigmobile {
		@media (min-width: 400px)  { @content ; }
	}
}