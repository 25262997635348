.nuestros {
	padding: 72px 30px;
	background: $grey;
	
	.top {
		margin-bottom: 36px;
		
		h2 {
			font-size: 32px;
			line-height: 1.2;
			margin-bottom: 22px;
			padding-left: 16px;
			border-left: 4px $red solid;
			background: url('../img/bg-nuestros-servicios.svg') no-repeat center right;
			background-size: 60px;
		}
		
		p {
			a {
				font-weight: 700;
				color: $red;
				background: url('../img/bg-icon-pdf.svg') no-repeat;
				background-size: 30px;
				padding-left: 42px;
				max-width: 250px;
				display: block;
				
				&:hover {
					color: $black;
				}
			}
		}
	}

	.content {
		padding-left: 26px;
		
		li {
			line-height: 26px;
			margin-bottom: 8px;
			position: relative;
			
			&:before {
				content: "";
				background: $red;
				width: 8px;
				height: 8px;
				border-radius: 100%;
				position: absolute;
				top: 10px;
				left: -26px;
			}
		}
	}
	
	&.repuestos {
		background: $white-bone;
		
		.top {
			
			h2 {
				background-image: url('../img/bg-nuestros-repuestos.svg');
			}
		}
	}

	@include breakpoint(phablet) {
		padding: 72px 130px;

		.top {
			
			p {
				a {
					line-height: 43px;
					max-width: none;
				}
			}
		}
	}
	@include breakpoint(tablet) {
		padding: 72px 60px;
		display: flex;
		align-items: center;
		
		.top {
			flex: 50% 0 0;
			padding-right: 80px;
			margin: 0;
			
			h2 {
				font-size: 42px;
				background-size: contain;
			}
		}

		.content {
			flex: 50% 0 0;
		}

		&.repuestos {
			
			.top {
				order: 2;
			}
		}
	}
	@include breakpoint(laptop) {
		padding: 72px 100px;
		
		.top {
			// flex: 50% 0 0;
			padding-right: 80px;
			// margin: 0;
			
			h2 {
				font-size: 56px;
				background-size: contain;
			}
		}

		.content {
			// flex: 50% 0 0;
		}

		&.repuestos {
			
			.top {
				padding-right: 40px;
			}
		}
	}
	@include breakpoint(bigdesktop) {
		padding: 100px 10vw;
		
		.top {
			padding-right: 14%;
		}

		&.repuestos {
			
			.top {
				padding-right: 10%;
			}
		}		
	}
}