// Fonts
$font-base:	'Montserrat', sans-serif;

// Colors
$black: #1d1d1b;
$white: #fff;
$white-bone: #f3f3f3;
$red: #ff000c;
$grey: #d7d7d7;
$grey-txt: #9A9A99;
$grey-bone: #f4f4f4;
$grey-logo: #9A9A99;