.empresa {
	background: $white-bone;

	&_top {
		background: $black;
		color: $white;	
		padding: 72px 30px 72px 60px; 
		
		h2 {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
			font-size: 30px;
			margin-bottom: 16px;
			position: relative;
					
			&:before {
				content: "";
				background: $red;
				height: 3px;
				width: 60px;
				position: absolute;
				top: 22px;
				left: -90px;
			}
		}

		p {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}

		.more {
			display: flex;
			justify-content: flex-end;		
			margin: 16px 0 0 0;
		}

		.arrow {
			width: 35px;
			height: 25px;
			fill: $red;
			transition: fill .3s ease-in-out;
			
			&:hover {
				fill: $white;
			}
		}

		@include breakpoint (bigmobile) {
			display: grid;
			grid-template-columns: 75% 25%;
			
			.more {
				grid-column: 2 / 3;
				grid-row: 1 / 3;
				justify-self: end;
				align-self: center;			
				margin: 16px 0 0 0;
			}			
		}
	}

	&_timeline {
		background: $grey-bone;
		padding-bottom: 100px;
		
		.swiper-wrapper {
			display: block;
		}
		
		.swiper-slide {
			padding: 100px 30px 0;
		}

		.swiper-button {
			display: none;
		}
		
		h5 {
			font-size: 22px;
			font-weight: 700;
			margin-bottom: 22px;
			color: $red;
		}

		header {
			padding-top: 18px;
			
			h4 {
				color: $red;
				font-weight: 700;
				font-size: 14px;
			}

			h2 {
				font-size: 32px;
				line-height: 1.2;
				margin-bottom: 12px;
				
				span {
					color: $red;
				}
			}

			p {
				
				span {
					color: red;
				}
			}

			.items {
				padding-left: 32px;
				margin-top: 10px;
				
				li {
					position: relative;
					font-size: 14px;
					line-height: 1.4;
					margin-bottom: 4px;

					&:before {
						content: "•";
						color: $red;
						font-size: 22px;
						line-height: 18px;
						position: absolute;
						left: -16px;
					}
				}
			}			
		}

	}

	@include breakpoint (phablet) {
		
		&_timeline {
			padding: 0 120px 100px;
		}
	}
	@include breakpoint (tablet) {
		padding: 30px 0;
		display: grid;
		grid-template-columns: 1.5fr 1fr 1fr 1.5fr;
		position: relative;
		
		&:after {
			content: "";
			background: $grey;
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: 50vw;
			z-index: 1;
		}
		
		&_top {
			grid-column: 3 / 5;
			grid-row: 1 / 2;
			align-self: center;
			z-index: 3;
			display: block;
			border-radius: 3px 0 0 3px;
			box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.45);
			
			h2 {
				font-size: 38px;
			}

			.more {
				
			}
		}

		&_image {
			grid-column: 1 / 4;
			grid-row: 1 / 2;
			z-index: 2;
		}		

		&_timeline {
			padding: 0;

			.swiper-wrapper {
				display: flex;
			}
		
			.swiper-slide {
				padding: 100px 100px 0 200px;
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;
			}

			.swiper-button {
				display: block;
				background: url('../img/bg-arrow-empresa-next.svg') no-repeat center;
				width: 95px;
				height: 24px;
				background-size: contain;
				
				&-next {
					top: auto;
					right: 100px;
					bottom: 40px;
				}				
				&-prev {
					top: auto;
					left: auto;
					right: 205px;
					bottom: 40px;
					background-image: url('../img/bg-arrow-empresa-prev.svg');
				}
			}

			.swiper-pagination {
				bottom: 0;
				left: 60px;
				width: 30px;
				top: 0;
				display: flex;
				flex-direction: column;
				align-items: center;
		        justify-content: center;
				
				&:before {
					content: "";
					width: 1px;
					height: 100%;
					background: $grey-txt;
					position: absolute;
					top: 0;
					left: 50%;
					z-index: -1;
					
				}
				
				.swiper-pagination-bullet {
					margin: 6px 0;
					background: $grey-bone;
					border: 1px $grey-txt solid;
					width: 14px;
					height: 14px;
					opacity: 1;
					transition: all .2s ease-in-out;
					
					&-active {
						background: $red;
						border: none;
						width: 26px;
						height: 26px;
					}
				}
			}						

			h5 {
				flex: 100% 0 0;
				
			}

			header {
				flex: 50% 0 0;
				padding: 30px 0 30px 50px;
			}

			p {
				font-size: 16px;
				position: relative;
				padding-left: 32px;
				
				&:before {
					content: "";
					width: 22px;
					height: 2px;
					background: $red;
					position: absolute;
					top: 8px;
					left: 0;
				}
			}

			picture {
				flex: 50% 0 0;
				box-shadow: 3px 3px 1em rgba(29, 29, 27, .6);
				
				img {
					// height: 250px;
					// object-fit: contain;
				}
			}
		}
	}

	@include breakpoint (laptop) {
		
		&_top {
			padding: 80px;
			
			h2 {
				font-size: 46px;
		
				&:before {
					width: 80px;
					top: 30px;
					left: -120px;
				}				
			}
		}

		&_image {
			grid-column: 1 / 4;
			grid-row: 1 / 2;
		}	

		&_timeline {
			
			.swiper-slide {
				padding-bottom: 100px;		
			}

			.swiper-button {
				
				&-next, &-prev {
					bottom: 80px;
				}
				&-prev {
					bottom: 80px;
					
				}
			}			

			.swiper-pagination {
				
				.swiper-pagination-bullet {
					margin: 6px 0;
					width: 16px;
					height: 16px;
					
					&-active {
						width: 26px;
						height: 26px;
					}
				}
			}

			header {

				h2 {
					font-size: 42px;	
					margin-bottom: 22px;
				}
			}				
		}
	}
	@include breakpoint (bigdesktop) {
		padding: 100px 10vw;

		&_image {
			
			img {
				height: 640px;
				object-fit: cover;
			}
		}		

		&_timeline {
			
			.swiper-slide {
				padding: 100px 14vw 100px 18vw;
			}

			.swiper-pagination {
				left: 8vw;
				
				.swiper-pagination-bullet {
					margin: 6px 0;
					width: 18px;
					height: 18px;
					
					&-active {
						width: 30px;
						height: 30px;
					}
				}
			}			
		}
	}
}