@charset "UTF-8";
*, *:before, *:after {
  box-sizing: border-box; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

img {
  display: block;
  width: 100%; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 1.4; }

ul, li {
  list-style: none; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, svg, picture {
  display: block; }

body {
  font: 400 16px "Montserrat", sans-serif;
  color: #1d1d1b;
  line-height: 1;
  background: #fff;
  position: relative; }

a {
  text-decoration: none;
  transition: all .4s ease; }

p {
  line-height: 1.4; }

strong {
  font-weight: 700; }

#landingForm input, #landingForm textarea, #landingForm select, #landingForm2 input, #landingForm2 textarea, #landingForm2 select {
  width: 100%;
  background: #f3f3f3;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px #1d1d1b solid;
  color: #1d1d1b;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  transition: all .2s ease-in-out; }
  @media (min-width: 1024px) {
    #landingForm input, #landingForm textarea, #landingForm select, #landingForm2 input, #landingForm2 textarea, #landingForm2 select {
      margin: 0; } }
  #landingForm input::placeholder, #landingForm textarea::placeholder, #landingForm select::placeholder, #landingForm2 input::placeholder, #landingForm2 textarea::placeholder, #landingForm2 select::placeholder {
    color: #1d1d1b; }
  #landingForm input::-moz-placeholder, #landingForm textarea::-moz-placeholder, #landingForm select::-moz-placeholder, #landingForm2 input::-moz-placeholder, #landingForm2 textarea::-moz-placeholder, #landingForm2 select::-moz-placeholder {
    opacity: 1; }
  #landingForm input:focus, #landingForm textarea:focus, #landingForm select:focus, #landingForm2 input:focus, #landingForm2 textarea:focus, #landingForm2 select:focus {
    background: #1d1d1b;
    color: #fff; }
    #landingForm input:focus::placeholder, #landingForm textarea:focus::placeholder, #landingForm select:focus::placeholder, #landingForm2 input:focus::placeholder, #landingForm2 textarea:focus::placeholder, #landingForm2 select:focus::placeholder {
      color: #fff; }
  #landingForm input.error, #landingForm textarea.error, #landingForm select.error, #landingForm2 input.error, #landingForm2 textarea.error, #landingForm2 select.error {
    border-color: red; }

.button__rayen {
  font-weight: 700;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  border: 2px solid;
  border-color: #1d1d1b;
  color: #ff000c;
  background: #1d1d1b;
  overflow: hidden;
  padding: 0;
  width: 100%;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer; }
  .button__rayen:before, .button__rayen > span {
    padding: .4em 1em;
    transition: transform 0.3s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1); }
  .button__rayen > span {
    display: block;
    vertical-align: middle;
    color: #ff000c; }
  .button__rayen:before {
    color: #ff000c;
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    transform: translate3d(-100%, 0, 0); }
  .button__rayen:hover:before {
    transform: translate3d(0, 0, 0); }
  .button__rayen:hover > span {
    transform: translate3d(0, 100%, 0); }

a.button__rayen {
  display: block;
  margin: 0 auto;
  text-align: center; }

.grecaptcha-badge {
  opacity: 0; }

@media (min-width: 1200px) {
  .come-in {
    transform: translateY(250px);
    animation: come-in 0.8s ease forwards; }
  .come-in:nth-child(odd) {
    animation-duration: 0.6s; }
  .already-visible {
    transform: translateY(0);
    animation: none; } }

@keyframes come-in {
  to {
    transform: translateY(0); } }

.btn {
  display: inline-block; }

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  position: absolute;
  z-index: 10;
  padding: 20px; }
  .header .logo-panar {
    width: 95px;
    height: 32px;
    display: block;
    fill: #1d1d1b; }
  .header .whatsapp-header {
    width: 30px;
    height: 30px; }
  .header .menu-group {
    display: flex;
    align-items: flex-end; }
  .header .social_links {
    margin-right: 24px; }
    .header .social_links .icon {
      width: 30px;
      height: 30px;
      fill: #ff000c;
      transition: fill .3s ease; }
    .header .social_links li {
      padding: 0 12px; }
  .header__toggle {
    width: 32px; }
  .header__toggle-line {
    display: block;
    background: #ff000c;
    width: 100%;
    height: 4px;
    margin-bottom: 4px;
    opacity: 1;
    transition: opacity 600ms ease 200ms, top 300ms ease, left 300ms ease, background 300ms ease, transform 300ms ease 450ms; }
    .header__toggle-line:nth-child(1) {
      transform-origin: center; }
    .header__toggle-line:nth-child(3) {
      transform-origin: center; }
  .header.fixed {
    position: fixed;
    animation: headerFix .5s ease-out;
    background: transparent; }
  .header.is-active {
    z-index: 11; }
    .header.is-active .header__toggle-line:nth-child(1) {
      transform: translateY(8px) rotate(45deg); }
    .header.is-active .header__toggle-line:nth-child(2) {
      opacity: 0; }
    .header.is-active .header__toggle-line:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg); }
    .header.is-active .social_links .icon {
      fill: #fff; }
    .interna .header.is-active {
      background: transparent; }
  .interna .header {
    background: #fff;
    transition: background 600ms ease 200ms; }
  @media (min-width: 1024px) {
    .header {
      padding: 38px 32px 0;
      align-items: center;
      background: none; }
      .header .logo-panar {
        width: 136px; }
      .header .whatsapp-header {
        display: none; }
      .interna .header.fixed {
        position: fixed; }
      .interna .header {
        position: relative;
        padding: 20px 30px; } }
  @media (min-width: 1200px) {
    .header .menu-group {
      align-items: center; }
    .header .social_links .icon {
      width: 40px;
      height: 40px; }
    .header .social_links a:hover .icon {
      fill: #1d1d1b; } }
  @media (min-width: 1600px) {
    .header {
      padding: 35px 60px 0; } }

section.menu {
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 11;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.85);
  transition: opacity 400ms ease, left 100ms ease 600ms;
  pointer-events: none; }
  section.menu.is-visible {
    left: 0;
    opacity: 1;
    transition: left 100ms ease, opacity 400ms ease 200ms;
    overflow-x: scroll; }
  section.menu .menu-inner {
    margin: 0;
    text-align: right; }
    section.menu .menu-inner .menu-list {
      list-style: none;
      margin: 0;
      padding: 0; }
      section.menu .menu-inner .menu-list > li {
        font-size: 32px;
        margin-bottom: 6px; }
        section.menu .menu-inner .menu-list > li a {
          line-height: 38px;
          color: #fff;
          font-weight: 700;
          pointer-events: auto;
          transition: color .3s ease; }
          section.menu .menu-inner .menu-list > li a:hover {
            color: #ff000c; }
        section.menu .menu-inner .menu-list > li.submenu {
          position: relative;
          pointer-events: auto; }
          section.menu .menu-inner .menu-list > li.submenu a svg.model path {
            fill: #fff; }
          section.menu .menu-inner .menu-list > li.submenu:before {
            content: "";
            background: transparent;
            height: 3px;
            width: 100%;
            position: absolute;
            top: 20px;
            right: calc(-100% - 40px); }
          section.menu .menu-inner .menu-list > li.submenu:hover > a {
            color: #ff000c; }
          section.menu .menu-inner .menu-list > li.submenu:hover:before {
            background: #ff000c; }
          section.menu .menu-inner .menu-list > li.submenu ul li a:hover svg.model .red path {
            fill: #ff000c; }
          section.menu .menu-inner .menu-list > li.submenu ul li a:hover svg.model .grey path {
            fill: #9A9A99; }
        section.menu .menu-inner .menu-list > li.active {
          position: relative;
          line-height: 38px;
          color: #ff000c;
          font-weight: 700; }
          section.menu .menu-inner .menu-list > li.active:before {
            content: "";
            background: #ff000c;
            height: 3px;
            width: 100%;
            position: absolute;
            top: 20px;
            right: calc(-100% - 40px); }
        section.menu .menu-inner .menu-list > li > ul {
          padding-top: 30px; }
          section.menu .menu-inner .menu-list > li > ul li a {
            display: inline-block; }
          section.menu .menu-inner .menu-list > li > ul li .model {
            width: auto;
            height: 26px; }
    section.menu .menu-inner .whatsapp {
      display: none;
      margin-top: 12px;
      align-items: center;
      justify-content: center; }
      section.menu .menu-inner .whatsapp svg {
        width: 60px;
        margin-left: 12px; }
      section.menu .menu-inner .whatsapp span {
        color: #1d1d1b;
        font-size: 22px; }
  @media (min-width: 768px) {
    section.menu .menu-inner .menu-list li {
      font-size: 38px; }
      section.menu .menu-inner .menu-list li > ul {
        padding: 20px 0; } }
  @media (min-width: 1024px) {
    section.menu {
      margin-left: 50%;
      width: 50%;
      align-items: flex-end; }
      section.menu.is-visible {
        overflow: hidden; }
      section.menu .menu-inner {
        margin: 0 100px 0 0; }
      .interna section.menu.fixed {
        top: 0; } }
  @media (min-width: 1440px) {
    section.menu .menu-inner .menu-list li {
      font-size: 48px; } }

@keyframes headerFix {
  0% {
    top: -100px; }
  100% {
    top: 0; } }

.footer {
  padding: 30px 60px;
  text-align: center;
  color: #9A9A99; }
  .footer_logo {
    width: 130px;
    height: 100%;
    margin: 0 auto 40px; }
  .footer_link {
    display: block;
    color: #9A9A99;
    transition: color .3s ease; }
    .footer_link:hover {
      color: #ff000c; }
    .footer_link.wsp {
      background: url("../img/bg-wsp.svg") no-repeat left center;
      padding-left: 28px;
      background-size: contain; }
  @media (min-width: 1024px) {
    .footer {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      font-size: 16px; }
      .footer * {
        margin: 0; }
      .footer_logo {
        width: 250px;
        height: 62px;
        text-align: left; }
        .footer_logo img {
          width: auto;
          height: 100%; }
      .footer .local {
        text-align: right;
        width: 250px; } }
  @media (min-width: 1440px) {
    .footer .local {
      width: auto; } }

.sign {
  text-align: center;
  padding: 22px;
  font-size: 12px;
  background: #f4f4f4; }
  .sign a {
    font-weight: 700;
    color: #1d1d1b; }
  @media (min-width: 1024px) {
    .sign {
      text-align: right;
      padding-right: 60px; } }

.social {
  background: #1d1d1b;
  color: #fff;
  padding: 48px; }
  .social h3 {
    font-size: 30px;
    margin-bottom: 18px; }
    .social h3 span {
      color: #ff000c;
      display: block;
      font-size: 22px; }
  .social_links {
    display: flex;
    justify-content: center; }
    .social_links li {
      text-align: center;
      padding: 12px; }
    .social_links .icon {
      width: 90px;
      height: 90px;
      fill: #ff000c;
      transition: fill .3s ease; }
    .social_links a:hover .icon {
      fill: #fff; }
  @media (min-width: 1024px) {
    .social {
      padding: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .social_top {
        flex: 40% 0 0; }
        .social_top h3 span {
          font-size: inherit; }
        .social_top p {
          margin: 0;
          font-size: 28px; }
      .social_links {
        flex: 55% 0 0;
        justify-content: flex-end; }
        .social_links li {
          padding: 0 0 0 32px; }
        .social_links .icon {
          width: 102px;
          height: 102px;
          transition: fill .3s ease; }
          .social_links .icon:hover {
            fill: #fff; } }
  @media (min-width: 1200px) {
    .social_top h3 {
      font-size: 42px; }
    .social_top p {
      font-size: 30px; } }
  @media (min-width: 1440px) {
    .social {
      padding: 60px 100px; } }
  @media (min-width: 1600px) {
    .social {
      padding: 60px 10vw; }
      .social_top h3 {
        max-width: 500px; } }

.hero {
  height: 100vh;
  overflow: hidden; }
  .hero .swiper-container {
    height: 100%; }
    .hero .swiper-container .swiper-slide {
      position: relative;
      overflow: hidden; }
    .hero .swiper-container .swiper-pagination {
      left: 0;
      width: 100%;
      bottom: 30px;
      display: flex;
      justify-content: center; }
      .hero .swiper-container .swiper-pagination .swiper-pagination-bullet {
        width: 26px;
        height: 26px;
        margin: 0 6px;
        background: #fff;
        opacity: 1; }
        .hero .swiper-container .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
          background: #ff000c; }
  .hero img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
    position: absolute;
    z-index: 1; }
  .hero.interna {
    height: calc(100vh - 72px);
    position: relative;
    background: #EEEFE9; }
    .hero.interna h1 {
      font-size: 36px;
      color: #ff000c;
      line-height: 1;
      margin-bottom: 0; }
    .hero.interna h2 {
      font-size: 36px;
      color: #fff; }
    .hero.interna .hero_tagline {
      top: auto;
      bottom: 0;
      transform: initial; }
    .hero.interna.gracias h2 {
      font-size: 36px; }
    .hero.interna.contacto h1 {
      color: #1d1d1b; }
      .hero.interna.contacto h1 span {
        color: #ff000c; }
    .hero.interna.empresa {
      display: block;
      padding: 0; }
      .hero.interna.empresa:after {
        content: none; }
      .hero.interna.empresa h1 {
        color: #fff; }
      .hero.interna.empresa p {
        color: #fff; }
    .hero.interna.modelo {
      padding: 72px 0 0 0;
      height: auto; }
      .hero.interna.modelo picture {
        overflow: hidden;
        animation: hideHero 0.8s 3s forwards cubic-bezier(0.04, 0.91, 0.57, 1); }
      .hero.interna.modelo:after {
        content: none; }
      .hero.interna.modelo h1 {
        color: #fff; }
      .hero.interna.modelo p {
        color: #fff; }
      .hero.interna.modelo img {
        position: relative; }
  .hero_tagline {
    padding: 0 30px 40px;
    position: absolute;
    top: 74%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2; }
    .hero_tagline:before {
      content: "";
      background: #ff000c;
      height: 3px;
      width: 100px;
      position: absolute;
      top: 12px;
      left: -80px; }
      .wline .hero_tagline:before {
        content: none; }
    .hero_tagline .logo-jac-hero {
      width: 190px;
      height: 28px;
      margin-bottom: 22px; }
    .hero_tagline h1 {
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 22px; }
    .hero_tagline .button__rayen {
      border-color: #ff000c;
      color: #fff;
      background: #ff000c;
      border-radius: 30px; }
      .hero_tagline .button__rayen > span {
        color: #fff; }
      .hero_tagline .button__rayen:before {
        background: #fff; }
  .gracias .hero {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px; }
  @media (min-width: 768px) {
    .hero.interna h1 {
      font-size: 56px; }
    .hero.interna h2 {
      font-size: 56px; }
    .hero.interna img {
      object-position: center top; }
    .hero.interna .hero_tagline:before {
      top: 24px; }
    .hero_tagline {
      width: 80%; }
      .hero_tagline h1 {
        font-size: 46px;
        line-height: 56px; }
    .gracias .hero {
      justify-content: center; } }
  @media (min-width: 1024px) {
    .hero h1 {
      background: #1d1d1b;
      color: #fff;
      padding: 0 22px 0 0;
      position: relative;
      border-radius: 0 30px 30px 0; }
      .hero h1:before {
        content: '';
        width: 100%;
        left: -100%;
        height: 100%;
        background-color: #1d1d1b;
        position: absolute; }
    .hero_tagline {
      width: 50%;
      padding: 0 60px 40px; }
      .hero_tagline .button__rayen {
        width: 70%;
        margin: 0; }
      .hero_tagline.white {
        color: #fff; }
    .hero.interna {
      height: 62vh; }
      .hero.interna .hero_tagline {
        width: auto; }
      .hero.interna h1 {
        background: none;
        padding: inherit;
        position: initial;
        border-radius: 0; }
        .hero.interna h1:before {
          content: none; }
      .hero.interna img {
        height: calc(100vh - 72px); }
      .hero.interna.modelo {
        padding: 0; }
        .hero.interna.modelo img {
          height: calc(100vh - 360px);
          object-fit: contain;
          object-position: center; } }
  @media (min-width: 1200px) {
    .hero h1 {
      font-size: 56px;
      line-height: 66px; }
    .hero_tagline:before {
      left: -70px; }
    .hero.interna .hero_tagline {
      width: auto;
      padding-left: 86px; }
      .hero.interna .hero_tagline:before {
        top: 30px;
        left: -40px; }
    .hero.interna.empresa p {
      font-size: 32px; } }
  @media (min-width: 1440px) {
    .hero.interna h1, .hero.interna h2 {
      font-size: 86px; } }
  @media (min-width: 1600px) {
    .hero_tagline {
      width: 35%; } }

.proximamente {
  padding: 72px 30px; }
  .proximamente h3 {
    font-size: 24px;
    text-align: center; }
  @media (min-width: 1200px) {
    .proximamente h3 {
      font-size: 32px; } }
  @media (min-width: 1200px) {
    .proximamente {
      padding: 100px 0; } }

@keyframes hideHero {
  0% {
    max-height: 360px; }
  100% {
    max-height: 0px; } }

.empresa {
  background: #f3f3f3; }
  .empresa_top {
    background: #1d1d1b;
    color: #fff;
    padding: 72px 30px 72px 60px; }
    .empresa_top h2 {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      font-size: 30px;
      margin-bottom: 16px;
      position: relative; }
      .empresa_top h2:before {
        content: "";
        background: #ff000c;
        height: 3px;
        width: 60px;
        position: absolute;
        top: 22px;
        left: -90px; }
    .empresa_top p {
      grid-column: 1 / 2;
      grid-row: 2 / 3; }
    .empresa_top .more {
      display: flex;
      justify-content: flex-end;
      margin: 16px 0 0 0; }
    .empresa_top .arrow {
      width: 35px;
      height: 25px;
      fill: #ff000c;
      transition: fill .3s ease-in-out; }
      .empresa_top .arrow:hover {
        fill: #fff; }
    @media (min-width: 400px) {
      .empresa_top {
        display: grid;
        grid-template-columns: 75% 25%; }
        .empresa_top .more {
          grid-column: 2 / 3;
          grid-row: 1 / 3;
          justify-self: end;
          align-self: center;
          margin: 16px 0 0 0; } }
  .empresa_timeline {
    background: #f4f4f4;
    padding-bottom: 100px; }
    .empresa_timeline .swiper-wrapper {
      display: block; }
    .empresa_timeline .swiper-slide {
      padding: 100px 30px 0; }
    .empresa_timeline .swiper-button {
      display: none; }
    .empresa_timeline h5 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 22px;
      color: #ff000c; }
    .empresa_timeline header {
      padding-top: 18px; }
      .empresa_timeline header h4 {
        color: #ff000c;
        font-weight: 700;
        font-size: 14px; }
      .empresa_timeline header h2 {
        font-size: 32px;
        line-height: 1.2;
        margin-bottom: 12px; }
        .empresa_timeline header h2 span {
          color: #ff000c; }
      .empresa_timeline header p span {
        color: red; }
      .empresa_timeline header .items {
        padding-left: 32px;
        margin-top: 10px; }
        .empresa_timeline header .items li {
          position: relative;
          font-size: 14px;
          line-height: 1.4;
          margin-bottom: 4px; }
          .empresa_timeline header .items li:before {
            content: "•";
            color: #ff000c;
            font-size: 22px;
            line-height: 18px;
            position: absolute;
            left: -16px; }
  @media (min-width: 768px) {
    .empresa_timeline {
      padding: 0 120px 100px; } }
  @media (min-width: 1024px) {
    .empresa {
      padding: 30px 0;
      display: grid;
      grid-template-columns: 1.5fr 1fr 1fr 1.5fr;
      position: relative; }
      .empresa:after {
        content: "";
        background: #d7d7d7;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 50vw;
        z-index: 1; }
      .empresa_top {
        grid-column: 3 / 5;
        grid-row: 1 / 2;
        align-self: center;
        z-index: 3;
        display: block;
        border-radius: 3px 0 0 3px;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.45); }
        .empresa_top h2 {
          font-size: 38px; }
      .empresa_image {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
        z-index: 2; }
      .empresa_timeline {
        padding: 0; }
        .empresa_timeline .swiper-wrapper {
          display: flex; }
        .empresa_timeline .swiper-slide {
          padding: 100px 100px 0 200px;
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap; }
        .empresa_timeline .swiper-button {
          display: block;
          background: url("../img/bg-arrow-empresa-next.svg") no-repeat center;
          width: 95px;
          height: 24px;
          background-size: contain; }
          .empresa_timeline .swiper-button-next {
            top: auto;
            right: 100px;
            bottom: 40px; }
          .empresa_timeline .swiper-button-prev {
            top: auto;
            left: auto;
            right: 205px;
            bottom: 40px;
            background-image: url("../img/bg-arrow-empresa-prev.svg"); }
        .empresa_timeline .swiper-pagination {
          bottom: 0;
          left: 60px;
          width: 30px;
          top: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .empresa_timeline .swiper-pagination:before {
            content: "";
            width: 1px;
            height: 100%;
            background: #9A9A99;
            position: absolute;
            top: 0;
            left: 50%;
            z-index: -1; }
          .empresa_timeline .swiper-pagination .swiper-pagination-bullet {
            margin: 6px 0;
            background: #f4f4f4;
            border: 1px #9A9A99 solid;
            width: 14px;
            height: 14px;
            opacity: 1;
            transition: all .2s ease-in-out; }
            .empresa_timeline .swiper-pagination .swiper-pagination-bullet-active {
              background: #ff000c;
              border: none;
              width: 26px;
              height: 26px; }
        .empresa_timeline h5 {
          flex: 100% 0 0; }
        .empresa_timeline header {
          flex: 50% 0 0;
          padding: 30px 0 30px 50px; }
        .empresa_timeline p {
          font-size: 16px;
          position: relative;
          padding-left: 32px; }
          .empresa_timeline p:before {
            content: "";
            width: 22px;
            height: 2px;
            background: #ff000c;
            position: absolute;
            top: 8px;
            left: 0; }
        .empresa_timeline picture {
          flex: 50% 0 0;
          box-shadow: 3px 3px 1em rgba(29, 29, 27, 0.6); } }
  @media (min-width: 1200px) {
    .empresa_top {
      padding: 80px; }
      .empresa_top h2 {
        font-size: 46px; }
        .empresa_top h2:before {
          width: 80px;
          top: 30px;
          left: -120px; }
    .empresa_image {
      grid-column: 1 / 4;
      grid-row: 1 / 2; }
    .empresa_timeline .swiper-slide {
      padding-bottom: 100px; }
    .empresa_timeline .swiper-button-next, .empresa_timeline .swiper-button-prev {
      bottom: 80px; }
    .empresa_timeline .swiper-button-prev {
      bottom: 80px; }
    .empresa_timeline .swiper-pagination .swiper-pagination-bullet {
      margin: 6px 0;
      width: 16px;
      height: 16px; }
      .empresa_timeline .swiper-pagination .swiper-pagination-bullet-active {
        width: 26px;
        height: 26px; }
    .empresa_timeline header h2 {
      font-size: 42px;
      margin-bottom: 22px; } }
  @media (min-width: 1600px) {
    .empresa {
      padding: 100px 10vw; }
      .empresa_image img {
        height: 640px;
        object-fit: cover; }
      .empresa_timeline .swiper-slide {
        padding: 100px 14vw 100px 18vw; }
      .empresa_timeline .swiper-pagination {
        left: 8vw; }
        .empresa_timeline .swiper-pagination .swiper-pagination-bullet {
          margin: 6px 0;
          width: 18px;
          height: 18px; }
          .empresa_timeline .swiper-pagination .swiper-pagination-bullet-active {
            width: 30px;
            height: 30px; } }

.postventa {
  background: #f3f3f3;
  padding: 72px 0; }
  .postventa#contacto {
    background: #d7d7d7;
    padding: 72px 0;
    margin: 0;
    align-items: center; }
    .postventa#contacto .top {
      margin: 0 0 30px 0;
      text-align: center; }
      .postventa#contacto .top .icon {
        width: 80px;
        height: 72px;
        margin: 0 auto 12px; }
      .postventa#contacto .top h2 {
        margin: 0 0 16px 0;
        padding: 0;
        border: none; }
    .postventa#contacto form input {
      background: #d7d7d7; }
      .postventa#contacto form input:focus {
        background: #1d1d1b; }
    .postventa#contacto form select {
      color: #1d1d1b;
      appearance: none;
      background: #d7d7d7 url("../img/bg-arrow-select.svg") no-repeat;
      background-size: 26px;
      background-position: right 12px center;
      border-radius: 0; }
    .interna .postventa#contacto .top {
      text-align: left;
      margin: 0 60px 60px;
      padding: 0; }
      .interna .postventa#contacto .top h2 {
        color: #ff000c;
        position: relative;
        padding: 0 0 16px 0;
        margin-bottom: 32px; }
        .interna .postventa#contacto .top h2:after {
          content: "";
          background: #ff000c;
          height: 4px;
          width: 110px;
          position: absolute;
          bottom: 0;
          left: 0; }
      .interna .postventa#contacto .top ul li {
        margin-bottom: 12px;
        padding-left: 56px; }
        .interna .postventa#contacto .top ul li a {
          color: #1d1d1b;
          font-weight: 700; }
          .interna .postventa#contacto .top ul li a:hover {
            color: #ff000c; }
        .interna .postventa#contacto .top ul li.dir {
          background: url("../img/icon-contact-dir.svg") no-repeat center left;
          background-size: 38px 38px; }
        .interna .postventa#contacto .top ul li.tel {
          background: url("../img/icon-contact-tel.svg") no-repeat center left;
          background-size: 38px 38px; }
        .interna .postventa#contacto .top ul li.mail {
          background: url("../img/icon-contact-mail.svg") no-repeat center left;
          background-size: 38px 38px; }
    .interna .postventa#contacto form {
      margin: 0 60px; }
      .interna .postventa#contacto form h2 {
        color: #ff000c;
        font-size: 30px;
        position: relative;
        margin-bottom: 32px;
        padding: 0 0 16px 0; }
        .interna .postventa#contacto form h2:after {
          content: "";
          background: #ff000c;
          height: 4px;
          width: 110px;
          position: absolute;
          bottom: 0;
          left: 0; }
    .interna .postventa#contacto.consultas .top {
      text-align: center; }
      .interna .postventa#contacto.consultas .top h2 {
        border: none;
        margin-bottom: 0; }
        .interna .postventa#contacto.consultas .top h2:after {
          content: none; }
  .postventa .top {
    padding: 0 30px;
    margin: 0 0 72px 30px; }
    .postventa .top h2 {
      font-size: 30px;
      margin-left: -30px;
      margin-bottom: 16px;
      padding-left: 30px;
      border-left: 3px #ff000c solid; }
      .postventa .top h2 span {
        color: #ff000c;
        display: block; }
      .postventa .top h2 em {
        text-decoration: none;
        font-size: 22px; }
    .postventa .top ul {
      margin-bottom: 22px; }
      .postventa .top ul li {
        background: url("../img/bg-tilde.svg") no-repeat center left;
        background-size: 26px 20px;
        padding-left: 38px;
        line-height: 38px; }
    .postventa .top a {
      color: #ff000c; }
  .postventa form {
    margin: 0 30px; }
  @media (min-width: 768px) {
    .postventa form {
      margin: 0 130px; } }
  @media (min-width: 1024px) {
    .postventa {
      display: flex;
      padding: 72px 30px;
      margin: 0; }
      .postventa#contacto {
        padding: 72px 30px; }
        .postventa#contacto .top {
          padding: 0 30px 0 0; }
          .postventa#contacto .top h2 {
            font-size: 32px; }
        .postventa#contacto form .mcontacto {
          grid-column: 1 / 3;
          grid-row: 3 / 4;
          margin: 0; }
        .postventa#contacto form .minteres {
          grid-column: 1 / 3;
          grid-row: 4 / 5;
          margin: 0; }
        .postventa#contacto form .button__rayen {
          grid-row: 5 / 6; }
        .postventa#contacto form input, .postventa#contacto form textarea, .postventa#contacto form select {
          margin: 0; }
        .interna .postventa#contacto .top {
          margin: 0; }
          .interna .postventa#contacto .top h2 {
            font-size: 36px;
            margin-bottom: 52px; }
        .interna .postventa#contacto form {
          margin: 0; }
          .interna .postventa#contacto form h2 {
            font-size: 36px;
            margin-bottom: 52px;
            grid-column: 1 / 3;
            grid-row: 1 / 2; }
          .interna .postventa#contacto form #pilot_firstname_contact, .interna .postventa#contacto form #pilot_firstname_post {
            grid-column: 1 / 3;
            grid-row: 2 / 3; }
          .interna .postventa#contacto form #pilot_cellphone_contact, .interna .postventa#contacto form #pilot_cellphone_post {
            grid-column: 1 / 2;
            grid-row: 3 / 4; }
          .interna .postventa#contacto form #pilot_email_contact, .interna .postventa#contacto form #pilot_email_post {
            grid-column: 2 / 3;
            grid-row: 3 / 4; }
          .interna .postventa#contacto form .mcontacto {
            grid-column: 1 / 3;
            grid-row: 4 / 5;
            margin: 0; }
          .interna .postventa#contacto form .minteres {
            grid-column: 1 / 3;
            grid-row: 5 / 6;
            margin: 0; }
          .interna .postventa#contacto form .button__rayen {
            grid-row: 6 / 7; }
      .postventa .top {
        flex: 40% 0 0;
        margin: 0; }
        .postventa .top h2 {
          font-size: 42px; }
      .postventa form {
        flex: 60% 0 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px; }
        .postventa form input, .postventa form textarea {
          margin: 0; }
        .postventa form #pilot_firstname_contact, .postventa form #pilot_firstname_post, .postventa form #pilot_firstname_interna {
          grid-column: 1 / 3;
          grid-row: 1 / 2; }
        .postventa form #pilot_cellphone_contact, .postventa form #pilot_cellphone_post, .postventa form #pilot_cellphone_interna {
          grid-column: 1 / 2;
          grid-row: 2 / 3; }
        .postventa form #pilot_email_contact, .postventa form #pilot_email_post, .postventa form #pilot_email_interna {
          grid-column: 2 / 3;
          grid-row: 2 / 3; }
        .postventa form #pilot_notes_contact, .postventa form #pilot_notes_post, .postventa form #pilot_notes_interna {
          grid-column: 1 / 3;
          grid-row: 3 / 4; }
        .postventa form .button__rayen {
          grid-column: 1 / 3;
          grid-row: 4 / 5; } }
  @media (min-width: 1200px) {
    .postventa {
      padding: 72px 90px;
      justify-content: space-between; }
      .postventa#contacto .top .icon {
        width: 126px;
        height: 116px;
        margin: 0 auto 32px; }
      .postventa#contacto .top p {
        font-size: 18px; }
      .interna .postventa#contacto {
        padding: 60px; }
        .interna .postventa#contacto .top ul li {
          margin-bottom: 36px; }
        .interna .postventa#contacto form {
          margin-top: 80px; }
      .postventa .top h2 em {
        font-size: 30px; }
      .postventa form {
        max-width: 680px; } }
  @media (min-width: 1440px) {
    .interna .postventa#contacto {
      padding: 60px 100px; } }
  @media (min-width: 1600px) {
    .postventa {
      padding: 100px 10vw; }
      .interna .postventa#contacto {
        padding: 100px 10vw; } }

.modelo + .postventa#contacto {
  background: #f3f3f3; }
  .modelo + .postventa#contacto form select {
    background-color: #f3f3f3; }
  .modelo + .postventa#contacto form input {
    background: #f3f3f3; }
    .modelo + .postventa#contacto form input:focus {
      background: #1d1d1b; }

.modelo_home {
  background: #d7d7d7;
  padding: 72px 0; }
  .modelo_home .top {
    padding: 0 30px;
    margin: 0 0 0 30px; }
    .modelo_home .top h2 {
      font-size: 30px;
      margin-left: -30px;
      margin-bottom: 16px;
      padding-left: 30px;
      border-left: 3px #ff000c solid; }
      .modelo_home .top h2 span {
        color: #ff000c;
        display: block; }
    .modelo_home .top p {
      font-size: 18px; }
    .modelo_home .top .more {
      display: flex;
      align-items: center;
      margin-top: 28px; }
      .modelo_home .top .more span {
        color: #ff000c;
        font-weight: 700;
        line-height: 26px;
        margin-right: 18px; }
      .modelo_home .top .more .arrow {
        fill: #ff000c;
        width: 35px;
        height: 26px; }
  .modelo_home .menu {
    padding: 0 60px; }
    .modelo_home .menu .modelo_home_slide {
      display: block; }
    .modelo_home .menu .swiper-button-next, .modelo_home .menu .swiper-button-prev {
      display: none; }
    .modelo_home .menu .model {
      width: 120px;
      height: 20px;
      margin: -20px auto 0 auto; }
    .modelo_home .menu .more {
      display: block; }
      .modelo_home .menu .more img {
        max-width: 280px; }

.modelo .tab-menu {
  display: none; }

.modelo .tabs-content {
  background: #f3f3f3;
  padding: 72px 0 0 0; }
  .modelo .tabs-content .top {
    margin-bottom: 36px;
    padding: 0 30px; }
    .modelo .tabs-content .top h2 {
      font-size: 32px;
      line-height: 1.2;
      margin-bottom: 22px;
      padding-left: 16px;
      border-left: 4px #ff000c solid; }
    .modelo .tabs-content .top p {
      font-size: 14px; }
    .modelo .tabs-content .top .ficha {
      font-size: 14px;
      font-weight: 700;
      color: #ff000c;
      background: url("../img/bg-icon-pdf.svg") no-repeat;
      background-size: 30px;
      padding-left: 42px;
      line-height: 43px;
      display: block; }
      .modelo .tabs-content .top .ficha:hover {
        color: #1d1d1b; }
    .modelo .tabs-content .top .cotizacion {
      border-color: #ff000c;
      background: #ff000c;
      color: #fff;
      font-size: 18px;
      margin-top: 22px; }
      .modelo .tabs-content .top .cotizacion > span {
        color: #fff; }
      .modelo .tabs-content .top .cotizacion:before {
        color: #ff000c;
        background: #fff; }
  .modelo .tabs-content .content .versiones {
    background: #d7d7d7;
    padding: 72px 0; }
  .modelo .tabs-content .content-body {
    margin-bottom: 42px; }
    .modelo .tabs-content .content-body .item .subtitle {
      background: #d7d7d7;
      font-size: 18px;
      padding: 10px 30px; }
    .modelo .tabs-content .content-body .item .items {
      padding-left: 32px;
      margin: 30px 20px; }
      .modelo .tabs-content .content-body .item .items li {
        position: relative;
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 4px; }
        .modelo .tabs-content .content-body .item .items li:before {
          content: "•";
          color: #ff000c;
          font-size: 22px;
          line-height: 18px;
          position: absolute;
          left: -16px; }
    .modelo .tabs-content .content-body .item-model {
      padding: 0 70px;
      text-align: center; }
      .modelo .tabs-content .content-body .item-model .modelo {
        width: 216px;
        height: 16px;
        margin: 0 auto; }
      .modelo .tabs-content .content-body .item-model .block {
        margin-top: -30px;
        display: block; }
      .modelo .tabs-content .content-body .item-model p {
        font-size: 22px;
        font-size: 700;
        color: #ff000c; }
        .modelo .tabs-content .content-body .item-model p .ficha {
          font-size: 14px;
          font-weight: 700;
          color: #ff000c;
          background: url("../img/bg-icon-pdf.svg") no-repeat;
          background-size: 30px;
          padding-left: 42px;
          line-height: 43px;
          display: inline-block; }
          .modelo .tabs-content .content-body .item-model p .ficha:hover {
            color: #1d1d1b; }
        .modelo .tabs-content .content-body .item-model p .price {
          margin: 0;
          font-size: 32px; }
      .modelo .tabs-content .content-body .item-model .cotizacion {
        border-color: #ff000c;
        background: #ff000c;
        color: #fff;
        font-size: 14px;
        margin-top: 22px; }
        .modelo .tabs-content .content-body .item-model .cotizacion > span {
          color: #fff; }
        .modelo .tabs-content .content-body .item-model .cotizacion:before {
          color: #ff000c;
          background: #fff; }
  .modelo .tabs-content .gallery_slide .swiper-button-next, .modelo .tabs-content .gallery_slide .swiper-button-prev {
    background-image: url("../img/bg-arrow-gallery.svg"); }
  .modelo .tabs-content .gallery_slide .swiper-button-prev {
    transform: rotate(0.5turn); }
  .modelo .tabs-content .gallery-thumbs {
    height: 0;
    box-sizing: border-box; }
  .modelo .tabs-content .gallery-thumbs .swiper-slide {
    background-size: cover;
    background-position: center;
    opacity: 0.4; }
  .modelo .tabs-content .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1; }

.modelo .tabs {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  font-weight: 300;
  font-size: 1.25em; }
  .modelo .tabs .tabs-nav {
    text-align: center; }
    .modelo .tabs .tabs-nav ul {
      position: relative;
      display: flex;
      margin: 0 auto;
      padding: 0;
      list-style: none;
      flex-flow: row wrap;
      justify-content: center; }
    .modelo .tabs .tabs-nav ul li {
      position: relative;
      z-index: 1;
      display: block;
      margin: 0;
      text-align: center;
      flex: 1; }
    .modelo .tabs .tabs-nav a {
      position: relative;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 2.5; }
      .modelo .tabs .tabs-nav a img {
        filter: grayscale(1);
        transition: all .2s ease-in-out; }
      .modelo .tabs .tabs-nav a:hover img {
        filter: grayscale(0); }
    .modelo .tabs .tabs-nav a span {
      vertical-align: middle;
      font-size: 0.75em; }
    .modelo .tabs .tabs-nav li.tab-current a img {
      filter: grayscale(0); }
    .modelo .tabs .tabs-nav a:focus {
      outline: none; }

.modelo .content-wrap {
  position: relative; }
  .modelo .content-wrap section {
    margin: 0 auto;
    height: 0;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    transition: all .2s ease-in-out; }
    .modelo .content-wrap section.content-current {
      height: 100%;
      opacity: 1;
      visibility: visible; }
  .modelo .content-wrap .banner {
    position: relative; }
    .modelo .content-wrap .banner_tagline {
      display: none; }
    .modelo .content-wrap .banner video {
      object-fit: contain;
      width: 100vw;
      height: 100%;
      display: block; }
  .modelo .content-wrap p {
    margin: 0;
    padding: 0.75em 0;
    line-height: 1; }

.modelo .tabs-style-linemove nav {
  background: #d7d7d7;
  /* Move the line */
  /*			li:first-child.tab-current ~ li:last-child::before {
				transform: translate3d(-400%,0,0);
			}

			li:nth-child(2).tab-current ~ li:last-child::before {
				transform: translate3d(-300%,0,0);
			}

			li:nth-child(3).tab-current ~ li:last-child::before {
				transform: translate3d(-200%,0,0);
			}

			li:nth-child(4).tab-current ~ li:last-child::before {
				transform: translate3d(-100%,0,0);
			}*/
  /* MENU CON X200 */ }
  .modelo .tabs-style-linemove nav li:last-child::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: #ff000c;
    content: '';
    transition: transform 0.3s; }
  .modelo .tabs-style-linemove nav li:first-child.tab-current ~ li:last-child::before {
    transform: translate3d(-500%, 0, 0); }
  .modelo .tabs-style-linemove nav li:nth-child(2).tab-current ~ li:last-child::before {
    transform: translate3d(-400%, 0, 0); }
  .modelo .tabs-style-linemove nav li:nth-child(3).tab-current ~ li:last-child::before {
    transform: translate3d(-300%, 0, 0); }
  .modelo .tabs-style-linemove nav li:nth-child(4).tab-current ~ li:last-child::before {
    transform: translate3d(-200%, 0, 0); }
  .modelo .tabs-style-linemove nav li:nth-child(5).tab-current ~ li:last-child::before {
    transform: translate3d(-100%, 0, 0); }
  .modelo .tabs-style-linemove nav a {
    padding: 0 10px 1em 10px;
    color: #74777b;
    line-height: 1;
    transition: color 0.3s, transform 0.3s; }
    .modelo .tabs-style-linemove nav a span {
      font-weight: 700; }
  .modelo .tabs-style-linemove nav li.tab-current a {
    color: #2CC185;
    transform: translate3d(0, 8px, 0); }

@media (min-width: 768px) {
  .modelo_home .menu .modelo_home_slide {
    display: flex;
    flex-wrap: wrap; }
    .modelo_home .menu .modelo_home_slide .swiper-slide {
      flex: 50% 0 0; }
  .modelo .tabs-content .versiones .content-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    .modelo .tabs-content .versiones .content-body .item-model {
      flex: 50%; } }

@media (min-width: 1024px) {
  .modelo_home {
    padding: 0 0 68px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    .modelo_home .top {
      flex: 26% 0 0;
      margin: 0;
      padding: 0 60px 0 0;
      text-align: right; }
      .modelo_home .top h2 {
        margin: 0;
        padding: 0;
        border: none; }
      .modelo_home .top .more {
        justify-content: flex-end; }
    .modelo_home .menu {
      width: 750px;
      padding: 0;
      order: -1; }
      .modelo_home .menu .modelo_home_slide {
        flex-wrap: inherit; }
      .modelo_home .menu .swiper-button-next, .modelo_home .menu .swiper-button-prev {
        display: block;
        height: 44px;
        width: 44px; }
      .modelo_home .menu .swiper-button-next {
        background-image: url("../img/bg-arrow-grey-next.svg"); }
      .modelo_home .menu .swiper-button-prev {
        background-image: url("../img/bg-arrow-grey-prev.svg"); }
      .modelo_home .menu .more {
        display: flex;
        flex-flow: column nowrap;
        align-items: center; }
  .modelo .content-wrap .banner_tagline {
    display: block;
    width: 40%;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.7);
    padding: 20px 20px 0 60px; }
    .modelo .content-wrap .banner_tagline:before {
      content: "";
      background: #ff000c;
      height: 3px;
      width: 100px;
      position: absolute;
      top: 30px;
      left: -70px; }
    .modelo .content-wrap .banner_tagline .logo-jac {
      width: auto;
      height: 30px; }
    .modelo .content-wrap .banner_tagline p {
      color: #fff;
      font-size: 18px;
      line-height: 1.4;
      font-weight: 400; }
  .modelo .tabs-style-linemove .tabs-nav {
    padding: 0 30px; }
    .modelo .tabs-style-linemove .tabs-nav img {
      width: 140px;
      margin: 0 auto; }
    .modelo .tabs-style-linemove .tabs-nav .model {
      width: 120px;
      height: 18px;
      margin: 0 auto 20px; }
  .modelo .tabs-style-linemove .tabs-content {
    position: relative;
    padding-top: 0; }
    .modelo .tabs-style-linemove .tabs-content:before {
      background: #1d1d1b;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 66px;
      z-index: 1; }
    .modelo .tabs-style-linemove .tabs-content > input {
      position: absolute;
      z-index: 1000;
      width: 240px;
      height: 40px;
      left: 30px;
      top: 12px;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      cursor: pointer; }
      .modelo .tabs-style-linemove .tabs-content > input:nth-child(3) {
        width: 130px; }
      .modelo .tabs-style-linemove .tabs-content > input:hover + label {
        color: #ff000c; }
      .modelo .tabs-style-linemove .tabs-content > input:checked + label {
        color: #ff000c;
        z-index: 6; }
      .modelo .tabs-style-linemove .tabs-content > input.tab-selector-1:checked ~ .content .content-1,
      .modelo .tabs-style-linemove .tabs-content > input.tab-selector-2:checked ~ .content .content-2 {
        z-index: 100;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100);
        opacity: 1;
        height: 100%;
        transition: all ease-out 0.2s 0.1s; }
    .modelo .tabs-style-linemove .tabs-content label {
      color: #fff;
      font-size: 16px;
      line-height: 66px;
      height: 66px;
      position: relative;
      padding: 0 30px;
      float: left;
      letter-spacing: 1px;
      font-weight: 700;
      text-align: center; }
      .modelo .tabs-style-linemove .tabs-content label:first-of-type {
        z-index: 4; }
    .modelo .tabs-style-linemove .tabs-content .tab-label-2 {
      z-index: 3; }
    .modelo .tabs-style-linemove .tabs-content .tab-menu#tab-2 {
      left: 300px; }
    .modelo .tabs-style-linemove .tabs-content .content {
      position: relative;
      z-index: 5; }
      .modelo .tabs-style-linemove .tabs-content .content:before {
        content: "";
        display: block;
        clear: both; }
      .modelo .tabs-style-linemove .tabs-content .content > div {
        padding-top: 72px; }
    .modelo .tabs-style-linemove .tabs-content .top {
      display: grid;
      grid-template-columns: repeat(2, 1fr); }
      .modelo .tabs-style-linemove .tabs-content .top h2 {
        padding-right: 160px;
        align-self: center; }
      .modelo .tabs-style-linemove .tabs-content .top .ficha {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        align-self: center; }
      .modelo .tabs-style-linemove .tabs-content .top p {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        align-self: center; }
        .modelo .tabs-style-linemove .tabs-content .top p strong {
          display: block; }
      .modelo .tabs-style-linemove .tabs-content .top .cotizacion {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        align-self: center;
        margin: 0;
        width: 320px; }
    .modelo .tabs-style-linemove .tabs-content .especificaciones .content-body {
      display: flex;
      flex-wrap: wrap; }
      .modelo .tabs-style-linemove .tabs-content .especificaciones .content-body .item {
        flex: 50% 0 0; }
        .modelo .tabs-style-linemove .tabs-content .especificaciones .content-body .item:nth-child(1), .modelo .tabs-style-linemove .tabs-content .especificaciones .content-body .item:nth-child(4) {
          flex: 100% 0 0; }
          .modelo .tabs-style-linemove .tabs-content .especificaciones .content-body .item:nth-child(1) .items, .modelo .tabs-style-linemove .tabs-content .especificaciones .content-body .item:nth-child(4) .items {
            columns: 2;
            column-gap: 30px;
            padding: 0 60px; }
    .modelo .tabs-style-linemove .tabs-content .versiones .content-body {
      padding: 0 30px; }
    .modelo .tabs-style-linemove .tabs-content .versiones .item-model {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 0 30px; }
      .modelo .tabs-style-linemove .tabs-content .versiones .item-model img {
        align-self: center; }
      .modelo .tabs-style-linemove .tabs-content .versiones .item-model .block {
        margin: 0;
        padding: 30px 0 0 20px;
        align-self: center; }
        .modelo .tabs-style-linemove .tabs-content .versiones .item-model .block .modelo {
          width: 100%; }
      .modelo .tabs-style-linemove .tabs-content .versiones .item-model .price {
        font-size: 32px;
        padding: 0; }
      .modelo .tabs-style-linemove .tabs-content .versiones .item-model .cotizacion {
        margin: 0;
        font-size: 13px; } }

@media (min-width: 1200px) {
  .modelo_home .top {
    overflow: hidden;
    flex: 30% 0 0; }
    .modelo_home .top h2 {
      font-size: 42px;
      line-height: 1;
      margin-bottom: 16px;
      position: relative; }
      .modelo_home .top h2:before {
        content: "";
        background: #ff000c;
        height: 3px;
        width: 60px;
        position: absolute;
        top: 20px;
        right: -90px; }
  .modelo_home .menu {
    width: 890px; }
  .modelo .content-wrap .banner_tagline {
    width: 50%;
    padding-top: 24px; }
    .modelo .content-wrap .banner_tagline .logo-jac {
      width: auto;
      height: 42px; }
    .modelo .content-wrap .banner_tagline p {
      font-size: 22px; }
  .modelo .tabs-style-linemove .tabs-content > input {
    left: 60px; }
    .modelo .tabs-style-linemove .tabs-content > input:nth-child(3) {
      width: 130px;
      margin-left: 30px; }
  .modelo .tabs-style-linemove .tabs-content label:first-of-type {
    padding-left: 60px; }
  .modelo .tabs-style-linemove .tabs-content .top {
    padding: 0 60px; }
    .modelo .tabs-style-linemove .tabs-content .top h2 {
      font-size: 42px; }
  .modelo .tabs-style-linemove .tabs-content .content-body .subtitle {
    padding: 10px 60px; }
  .modelo .tabs-style-linemove .tabs-content .content-body .item .items {
    padding-left: 62px; }
  .modelo .tabs-style-linemove .tabs-content .content-body .item:first-child .items {
    padding: 0px 60px; }
  .modelo .tabs-style-linemove .tabs-content .content-body .item:nth-child(3) .subtitle {
    padding-left: 0; }
  .modelo .tabs-style-linemove .tabs-content .content-body .item:nth-child(3) .items {
    margin-left: 0;
    padding-left: 16px; }
  .modelo .tabs-style-linemove .tabs-content .versiones .item-model .cotizacion {
    font-size: 16px; } }

@media (min-width: 1440px) {
  .modelo_home {
    padding-top: 16px; }
    .modelo_home .top {
      overflow: hidden; }
      .modelo_home .top h2 {
        font-size: 56px; }
        .modelo_home .top h2:before {
          top: 26px; }
    .modelo_home .menu {
      width: 930px; } }

@media (min-width: 1600px) {
  .modelo_home {
    padding-left: 60px; }
  .modelo .content-wrap .banner_tagline p {
    font-size: 28px; } }

.design .top {
  background: #1d1d1b;
  color: #fff;
  padding: 72px 30px 72px 60px; }
  .design .top h2 {
    font-size: 30px;
    margin-bottom: 16px;
    position: relative; }
    .design .top h2:before {
      content: "";
      background: #ff000c;
      height: 3px;
      width: 60px;
      position: absolute;
      top: 22px;
      left: -90px; }
  .design .top .more {
    display: flex;
    margin: 16px 0 0 0; }
    .design .top .more span {
      color: #ff000c;
      font-weight: 700;
      line-height: 26px;
      margin-right: 18px; }
    .design .top .more .arrow {
      width: 35px;
      height: 25px;
      fill: #ff000c; }
      .design .top .more .arrow:hover {
        fill: #fff; }

.design .gallery .slide_gallery .swiper-wrapper {
  display: block; }

.design .gallery .swiper-slide {
  height: 280px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px;
  color: white;
  font-size: 18px; }

@media (min-width: 1024px) {
  .design {
    padding: 30px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    overflow: hidden; }
    .design .gallery {
      width: calc(100% + 60px);
      grid-column: 2 / 4;
      grid-row: 1 / 2;
      align-self: center;
      z-index: 2;
      display: block;
      margin-left: -60px;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.45); }
      .design .gallery h2 {
        font-size: 38px; }
      .design .gallery .slide_gallery .swiper-wrapper {
        display: flex; }
    .design .top {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      min-height: 340px;
      width: 340px;
      border-radius: 0 3px 3px 0;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.45); } }

@media (min-width: 1200px) {
  .design {
    padding: 72px 0; }
    .design .top {
      padding: 80px;
      min-height: 480px;
      width: auto; }
      .design .top h2 {
        font-size: 46px; }
        .design .top h2:before {
          width: 80px;
          top: 30px;
          left: -120px; }
    .design .gallery .swiper-slide {
      height: 380px; } }

@media (min-width: 1600px) {
  .design {
    padding: 100px 0; } }

.contact {
  background: #fff; }
  .contact_title {
    color: #fff;
    font-size: 32px;
    padding: 48px;
    text-align: center; }
  .contact form {
    padding: 0 48px 48px; }
    .contact form input, .contact form textarea {
      border-color: #d7d7d7;
      color: #fff; }
      .contact form input::placeholder, .contact form textarea::placeholder {
        color: #d7d7d7; }
      .contact form input:focus, .contact form textarea:focus {
        border-color: #ff000c; }
        .contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
          color: #fff; }
  .interna .contact {
    background: #f3f3f3; }
  @media (min-width: 768px) {
    .contact form {
      max-width: 70%;
      margin: 0 auto; } }
  @media (min-width: 1024px) {
    .contact {
      padding: 0 60px 40px; }
      .contact form {
        width: 100%;
        max-width: none;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 26px; }
        .contact form * {
          margin: 0; }
        .contact form input {
          grid-column: 1 / 2;
          padding: 9px;
          height: 44px; }
        .contact form select {
          grid-column: 2 / 3;
          grid-row: 1 / 2; }
        .contact form textarea {
          grid-column: 2 / 3;
          grid-row: 1 / 3;
          padding: 9px; }
        .contact form .button__rayen {
          grid-column: 2 / 3;
          grid-row: 3 / 4; } }
  @media (min-width: 1200px) {
    .contact form {
      width: 800px; } }
  @media (min-width: 1440px) {
    .contact {
      padding: 0 0 40px; } }
  @media (min-width: 1600px) {
    .contact {
      padding: 0 0 80px; }
      .contact form {
        width: 1100px; } }

.nuestros {
  padding: 72px 30px;
  background: #d7d7d7; }
  .nuestros .top {
    margin-bottom: 36px; }
    .nuestros .top h2 {
      font-size: 32px;
      line-height: 1.2;
      margin-bottom: 22px;
      padding-left: 16px;
      border-left: 4px #ff000c solid;
      background: url("../img/bg-nuestros-servicios.svg") no-repeat center right;
      background-size: 60px; }
    .nuestros .top p a {
      font-weight: 700;
      color: #ff000c;
      background: url("../img/bg-icon-pdf.svg") no-repeat;
      background-size: 30px;
      padding-left: 42px;
      max-width: 250px;
      display: block; }
      .nuestros .top p a:hover {
        color: #1d1d1b; }
  .nuestros .content {
    padding-left: 26px; }
    .nuestros .content li {
      line-height: 26px;
      margin-bottom: 8px;
      position: relative; }
      .nuestros .content li:before {
        content: "";
        background: #ff000c;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        position: absolute;
        top: 10px;
        left: -26px; }
  .nuestros.repuestos {
    background: #f3f3f3; }
    .nuestros.repuestos .top h2 {
      background-image: url("../img/bg-nuestros-repuestos.svg"); }
  @media (min-width: 768px) {
    .nuestros {
      padding: 72px 130px; }
      .nuestros .top p a {
        line-height: 43px;
        max-width: none; } }
  @media (min-width: 1024px) {
    .nuestros {
      padding: 72px 60px;
      display: flex;
      align-items: center; }
      .nuestros .top {
        flex: 50% 0 0;
        padding-right: 80px;
        margin: 0; }
        .nuestros .top h2 {
          font-size: 42px;
          background-size: contain; }
      .nuestros .content {
        flex: 50% 0 0; }
      .nuestros.repuestos .top {
        order: 2; } }
  @media (min-width: 1200px) {
    .nuestros {
      padding: 72px 100px; }
      .nuestros .top {
        padding-right: 80px; }
        .nuestros .top h2 {
          font-size: 56px;
          background-size: contain; }
      .nuestros.repuestos .top {
        padding-right: 40px; } }
  @media (min-width: 1600px) {
    .nuestros {
      padding: 100px 10vw; }
      .nuestros .top {
        padding-right: 14%; }
      .nuestros.repuestos .top {
        padding-right: 10%; } }

.index {
  display: block; }
