.header {
	display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
	position: absolute;
	z-index: 10;
	padding: 20px;

	.logo-panar {
		width: 95px;
		height: 32px;
		display: block;
		fill: $black;
	}

	.whatsapp-header {
		width: 30px;
		height: 30px;
	}  

	.menu-group {
		display: flex;
		align-items: flex-end;
	}
	
	.social_links {
		margin-right: 24px;
	
		.icon {
			width: 30px;
			height: 30px;
			fill: #ff000c;
			transition: fill .3s ease;  
		}
		 
		li { 
			padding: 0 12px;
		}
	}	

	&__toggle {
	    width: 32px;
	}

	&__toggle-line {
	    display: block;
	    background: $red;
	    width: 100%;
	    height: 4px;
	    margin-bottom: 4px;
	    opacity: 1; 
	    transition: opacity 600ms ease 200ms, top 300ms ease, left 300ms ease, background 300ms ease, transform 300ms ease 450ms;    
		
		&:nth-child(1) {
		    transform-origin: center;
		}
		&:nth-child(3) {
		    transform-origin: center;
		}
	}    

	&.fixed {
		position: fixed;
		animation: headerFix .5s ease-out;
		background: transparent;
	}	

	&.is-active {
	    // position: fixed;
	    z-index: 11;

		.header__toggle-line {

			&:nth-child(1) {
			    transform: translateY(8px) rotate(45deg);
			    // background: $white;
			}
			&:nth-child(2) {
			    // background: $white;
			    opacity: 0;
			}
			&:nth-child(3) {
			    transform: translateY(-8px) rotate(-45deg);
			    // background: $white;
			}
		}

		.social_links {
	
			.icon {
				fill: #fff;
			}
		}		

		.interna & {
			background: transparent;
		}
	}

	.interna & {
		background: $white;
		transition: background 600ms ease 200ms;
	}

	@include breakpoint(tablet) {
		padding: 38px 32px 0;
		align-items: center;
		background: none;

		.logo-panar {
			width: 136px;
		}		
		
		&__toggle {
		    // display: none;
		}		

		.whatsapp-header {
			display: none;
		}
		
		&.fixed {
			
			.interna & {
				position: fixed;
			}
		}

		&.is-active {
		    // position: absolute;
		    // z-index: 10;
		}
		.interna & {
			position: relative;
			padding: 20px 30px;
		}
	}
	@include breakpoint(laptop) {

		.menu-group {
			align-items: center; 
		}		
	
		.social_links {
		
			.icon {
				width: 40px;
				height: 40px;
			}
			
			a:hover .icon {
				fill: $black;
			}			
		}
	}
	@include breakpoint(bigdesktop) {
		padding: 35px 60px 0;
	}
}

section.menu {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 11;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding-top: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    background: rgba(0,0,0, 0.85);
    transition: opacity 400ms ease, left 100ms ease 600ms;
    pointer-events: none;
	
	&.is-visible {
	    left: 0;
	    opacity: 1;
	    transition: left 100ms ease, opacity 400ms ease 200ms;
	    overflow-x: scroll;
	}
	
	.menu-inner {
	    margin: 0;
		text-align: right;
		
		.menu-list {
		    list-style: none;
		    margin: 0;
		    padding: 0;
		    
			> li {
			    font-size: 32px;   
			    margin-bottom: 6px;

				a {
				    line-height: 38px;
				    color: $white;
				    font-weight: 700;
				    pointer-events: auto;
				    transition: color .3s ease;
				    
				    &:hover {
				    	color: $red;
				    }
				}

				&.submenu {
					position: relative;
					pointer-events: auto;

					a {
						// color: $red;
						
						svg.model {
							
							path {fill: $white;}
						}
					}
					
					&:before {
						content: "";
						background: transparent;
						height: 3px;
						width: 100%;
						position: absolute;
						top: 20px;
						right: calc(-100% - 40px);
					}

					&:hover {

						> a {
					    	color: $red;
					    }

						&:before {
							background: $red;
						}
				    }

				    ul li a:hover {		
				    	
				    	svg.model {
							
							.red {
								path {
									fill: $red;
								}
							}
							.grey {
								path {
									fill: $grey-logo;
								}
							}
						}
					}
				}

				&.active {
					position: relative;
					line-height: 38px;
				    color: $red;
				    font-weight: 700;
					
					&:before {
						content: "";
						background: $red;
						height: 3px;
						width: 100%;
						position: absolute;
						top: 20px;
						right: calc(-100% - 40px);
					}
				}

				> ul {
					padding-top: 30px;

					li {
						a {
							display: inline-block;
						}
						.model {
							width: auto;
							height: 26px;
						}
					}
				}
			}
		}
	
		.whatsapp {
			display: none;
			margin-top: 12px;
			align-items: center;
			justify-content: center;
			
			svg {
				width: 60px;
				margin-left: 12px;
			}

			span {
				color: $black;
				font-size: 22px;
			}
		}
	}

	@include breakpoint(phablet) {

		.menu-inner {
			
			.menu-list {
			    
				li {
				    font-size: 38px;  	
				    
				    > ul {
						padding: 20px 0;
					}
				}
			}
		}	
	}
	@include breakpoint(tablet) {
		margin-left: 50%;
		width: 50%;
		align-items: flex-end;
		
		&.is-visible {
			overflow: hidden;		
		}
		
		.menu-inner {
			margin: 0 100px 0 0;

		}	

		.interna & {
			
			&.fixed {
		    	top: 0;
		    }
		}
	}
	@include breakpoint(desktop) {	

		.menu-inner {
			
			.menu-list {
			    
				li {
				    font-size: 48px;  
				}
			}
		}

	}
}

@keyframes headerFix {
  0%   { top: -100px; }
  100% { top: 0; }
}