.footer {
	padding: 30px 60px;
	text-align: center;
	color: $grey-txt;

	&_logo {
		width: 130px;
		height: 100%;
		margin: 0 auto 40px;
	}

	&_link {
		display: block;
		color: $grey-txt;
		transition: color .3s ease;
		
		&:hover {
			color: $red;
		}		
		
		&.wsp { 
			background: url('../img/bg-wsp.svg') no-repeat left center;
			padding-left: 28px;
			background-size: contain;
		}

	}

	@include breakpoint(tablet) {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
        justify-content: space-between;
		font-size: 16px;
		
		* { margin: 0;}

		&_logo {
			width: 250px;
			height: 62px;
			text-align: left;
			
			img {
				width: auto;
				height: 100%;
			}
		}

		.local {
			text-align: right;
			width: 250px;
		}
	}

	@include breakpoint(desktop) {

		.local {
			width: auto;
		}
	}
}

.sign {
	text-align: center;
	padding: 22px;
	font-size: 12px;
	background: $grey-bone;
	
	a {
		font-weight: 700;
		color: $black;
	}

	@include breakpoint(tablet) {
		text-align: right;
		padding-right: 60px;
		
	}
 }

.social {
	background: $black;
	color: $white;
	padding: 48px;

	h3 {
		font-size: 30px;
		margin-bottom: 18px;
		
		span {
			color: $red;
			display: block; 
			font-size: 22px;
		}
	}

	&_links {
		display: flex;
		justify-content: center;
		
		li {
			text-align: center;
			padding: 12px;			
		}

		.icon {
			width: 90px;
			height: 90px;
			fill: $red;
			transition: fill .3s ease;
		
		}
		a:hover .icon {
			fill: $white;
		}
	}

	@include breakpoint(phablet) {
	
		&_links {
			// display: flex;
			// justify-content: center;
		}
	}
	@include breakpoint(tablet) {
		padding: 60px;
		display: flex;
		align-items: center;
        justify-content: space-between;
		
		&_top {
			flex: 40% 0 0;
			
			h3 {
				span {
					font-size: inherit;
				}
			}
			
			p {
				margin: 0;
				font-size: 28px;
			}
		}
		
		&_links {
			flex: 55% 0 0;
			justify-content: flex-end;

			li {
				padding: 0 0 0 32px;			
			}

			.icon {
				width: 102px;
				height: 102px;
				transition: fill .3s ease;
				
				&:hover {
					fill: $white;
				}
			}

		}
	}
	@include breakpoint(laptop) {
		
		&_top {

			h3 { 
				font-size: 42px;
			}		
			p {
				 font-size: 30px;
			}
		}				
	}
	@include breakpoint(desktop) {
		padding: 60px 100px;		
	}
	@include breakpoint(bigdesktop) {
		padding: 60px 10vw;		
		
		&_top {

			h3 { 
				max-width: 500px;
			}
		}
	}
}