.modelo {

	& + .postventa#contacto {
		background: $white-bone;
		
		form {
			
			select {
				background-color: $white-bone;
			}
			input {
				background: $white-bone;

				&:focus {
					background: $black;
				}
			}
		}
	}
	
	&_home {
		background: $grey;
		padding: 72px 0;
		
		.top {
			padding: 0 30px;
			margin: 0 0 0 30px;
		
			h2 {
				font-size: 30px;
				margin-left: -30px;
				margin-bottom: 16px;
				padding-left: 30px;
				border-left: 3px $red solid;
				
				span {
					color: $red;
					display: block;
				}
			}

			p {
				font-size: 18px;
			}

			.more {
				display: flex;
				align-items: center;
				margin-top: 28px;

				span {
					color: $red;
					font-weight: 700;
					line-height: 26px;
					margin-right: 18px;
				}

				.arrow {
					fill: $red;
					width: 35px;
					height: 26px;
				}
			}
		}
	
		.menu {
			padding: 0 60px;
			
			.modelo_home_slide {
				display: block;
			}

			.swiper-button-next, .swiper-button-prev {
				display: none;
			}

			.model {
				width: 120px;
				height: 20px;
				margin: -20px auto 0 auto;
			}

			.more {
				display: block;
				
				img {
					max-width: 280px;
				}
			}
		}		
	}	

	// TAB INTERNA MODELO
	.tab-menu {
		display: none;
	}

	.tabs-content {
		background: $white-bone;
		padding: 72px 0 0 0;

		.top {
			margin-bottom: 36px;
			padding: 0 30px;
			
			h2 {
				font-size: 32px;
				line-height: 1.2;
				margin-bottom: 22px;
				padding-left: 16px;
				border-left: 4px $red solid;
				}
			
			p {
				font-size: 14px;
			}

			.ficha {
				font-size: 14px;
				font-weight: 700;
				color: $red;
				background: url('../img/bg-icon-pdf.svg') no-repeat;
				background-size: 30px;
				padding-left: 42px;
				line-height: 43px;
				display: block;
				
				&:hover {
					color: $black;
				}
			}

			.cotizacion {
				border-color: $red;
				background: $red;
				color: $white;
				font-size: 18px;
				margin-top: 22px;
				
				& > span {
				    color: $white;
				}				
				
				&:before {
					color: $red;
					background: $white;				
				}
			}
		}

		.content {
			
			.versiones {
				background: $grey;
				padding: 72px 0;
			}
		}

		.content-body {
			margin-bottom: 42px;
			
			.item {
				
				.subtitle {
					background: $grey;
					font-size: 18px;
					padding: 10px 30px;
				}

				.items {
					padding-left: 32px;
					margin: 30px 20px;
					
					li {
						position: relative;
						font-size: 14px;
						line-height: 1.4;
						margin-bottom: 4px;

						&:before {
							content: "•";
							color: $red;
							font-size: 22px;
							line-height: 18px;
							position: absolute;
							left: -16px;
						}
					}
				}
			}

			.item-model {
				padding: 0 70px;
				text-align: center;
				
				.modelo {
					width: 216px;
					height: 16px;
					margin: 0 auto;
				}

				.block {
					margin-top: -30px;
					display: block;
				}

				p {
					font-size: 22px;
					font-size: 700;
					color: $red;

					.ficha {
						font-size: 14px;
						font-weight: 700;
						color: $red;
						background: url('../img/bg-icon-pdf.svg') no-repeat;
						background-size: 30px;
						padding-left: 42px;
						line-height: 43px;
						display: inline-block;
						
						&:hover {
							color: $black;
						}
					}

					.price {
						margin: 0;
						font-size: 32px;
					}
				}	

				.cotizacion {
					border-color: $red;
					background: $red;
					color: $white;
					font-size: 14px;
					margin-top: 22px;
					
					& > span {
					    color: $white;
					}				
					
					&:before {
						color: $red;
						background: $white;				
					}
				}
			}
		}

		.gallery_slide {
			// display: none;

			.swiper-button-next, .swiper-button-prev {
				background-image: url('../img/bg-arrow-gallery.svg');
			}
			.swiper-button-prev {
			    transform: rotate(0.5turn);
			}
		}

		.gallery-thumbs {
			height: 0;
			box-sizing: border-box;
		}
		.gallery-thumbs .swiper-slide {
			background-size: cover;
	     	background-position: center;
			opacity: 0.4;
		}
		.gallery-thumbs .swiper-slide-thumb-active {
			opacity: 1;
		}

	}

	// TAB GRAL MODELOS
	.tabs {
		position: relative;
		overflow: hidden;
		margin: 0 auto;
		width: 100%;
		font-weight: 300;
		font-size: 1.25em;

		// Nav
		.tabs-nav {
			text-align: center;
			// position: sticky;
			// top: 376px;

			ul {
				position: relative;
				display: flex;
				margin: 0 auto;
				padding: 0;
				list-style: none;
				flex-flow: row wrap;
				justify-content: center;
			}

			ul li {
				position: relative;
				z-index: 1;
				display: block;
				margin: 0;
				text-align: center;
				flex: 1;
			}

			a {
				position: relative;
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				line-height: 2.5;
				
				img {
					filter: grayscale(1);
					transition: all .2s ease-in-out;
				}

				&:hover {
				
					img {
						filter: grayscale(0);
					}
				}
			}

			a span {
				vertical-align: middle;
				font-size: 0.75em;
			}

			li.tab-current a {
				// color: $red;
				
				img {
					filter: grayscale(0);
				}
			}

			a:focus {
				outline: none;
			}
		}
	}

	.content-wrap {
		position: relative;

		section {
			margin: 0 auto;
		    height: 0;
		    opacity: 0;
		    overflow: hidden;
		    visibility: hidden;
		    transition: all .2s ease-in-out;
			
		
			&.content-current {
				height: 100%;
    			opacity: 1;
    			visibility: visible;
			}

		}

		.banner {
			position: relative;
			
			&_tagline {
				display: none;
			}

			video {
				object-fit: contain;
				width: 100vw;
				height: 100%;
				display: block;
				// position: fixed;
				// top: 0;
				// left: 0;
			}
		}			
		
		p {
			margin: 0;
			padding: 0.75em 0;
			line-height: 1;
		}
	}

	.tabs-style-linemove {

		nav {
			background: $grey;
			
			li:last-child::before {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 4px;
				background: $red;
				content: '';
				transition: transform 0.3s;
			}

			/* Move the line */
/*			li:first-child.tab-current ~ li:last-child::before {
				transform: translate3d(-400%,0,0);
			}

			li:nth-child(2).tab-current ~ li:last-child::before {
				transform: translate3d(-300%,0,0);
			}

			li:nth-child(3).tab-current ~ li:last-child::before {
				transform: translate3d(-200%,0,0);
			}

			li:nth-child(4).tab-current ~ li:last-child::before {
				transform: translate3d(-100%,0,0);
			}*/

			/* MENU CON X200 */
	
			li:first-child.tab-current ~ li:last-child::before {
				transform: translate3d(-500%,0,0);
			}

			li:nth-child(2).tab-current ~ li:last-child::before {
				transform: translate3d(-400%,0,0);
			}

			li:nth-child(3).tab-current ~ li:last-child::before {
				transform: translate3d(-300%,0,0);
			}

			li:nth-child(4).tab-current ~ li:last-child::before {
				transform: translate3d(-200%,0,0);
			}

			li:nth-child(5).tab-current ~ li:last-child::before {
				transform: translate3d(-100%,0,0);
			}

			a {
				padding: 0 10px 1em 10px;
				color: #74777b;
				line-height: 1;
				transition: color 0.3s, transform 0.3s;
				
				span {
					font-weight: 700;
				}
			}

			li.tab-current a {
				color: #2CC185;
				transform: translate3d(0,8px,0);
			}

		}

	}

	@include breakpoint (phablet) {

		&_home {		
			
			.menu {

				.modelo_home_slide {
					display: flex;
					flex-wrap: wrap;
					
					.swiper-slide {
						flex: 50% 0 0;					
					}
				}
			}
		}

		.tabs-content {
			
			.versiones {

				.content-body {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					
					.item-model {
						flex: 50%;
					}
				}
			}
		}
	}

	@include breakpoint (tablet) {

		&_home {		
			padding: 0 0 68px 0;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			
			.top {
				flex: 26% 0 0;
				margin: 0;
				padding: 0 60px 0 0;
				text-align: right;
				
				h2 {
					margin: 0;
					padding: 0;
					border: none;
				}

				.more {
					justify-content: flex-end; 
				}
			}

			.menu {
				width: 750px;
				padding: 0;
				order: -1;

				.modelo_home_slide {
					flex-wrap: inherit;
				}

				.swiper-button-next, .swiper-button-prev {
					display: block;
					height: 44px;
					width: 44px;
				}

				.swiper-button-next {
					background-image: url('../img/bg-arrow-grey-next.svg');
				}

				.swiper-button-prev {
					background-image: url('../img/bg-arrow-grey-prev.svg');
				}		

				.more {
					display: flex;
					flex-flow: column nowrap; 
					align-items: center;
				}
			}
		}

		.content-wrap {

			.banner {

				&_tagline {
					display: block;
					width: 40%;
					position: absolute;
					top: 50%;
					left: 0px;
					transform: translateY(-50%);
					background: rgba(0,0,0,.7);
					padding: 20px 20px 0 60px;
					
					&:before {
						content: "";
						background: $red;
						height: 3px;
						width: 100px;
						position: absolute;
						top: 30px;
						left: -70px;
					}					
					
					.logo-jac {
						width: auto;
						height: 30px;
					}

					p {
						color: $white;
						font-size: 18px;
						line-height: 1.4;
						font-weight: 400;
					}
				}
			}
		}

		.tabs-style-linemove {

			.tabs-nav {
				padding: 0 30px;
								
				img {
					width: 140px;					
					margin: 0 auto;
				}
				.model {
					width: 120px;
					height: 18px;
					margin: 0 auto 20px;
				}
			}

			.tabs-content {
				position: relative;
				padding-top: 0;
				
				&:before {
					// content: "";
					background: $black;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 66px;
					z-index: 1;
				}

				& > input {
					position: absolute;
					z-index: 1000;
					width: 240px;
					height: 40px;
					left: 30px;
					top: 12px;
					opacity: 0;
				    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
				    filter: alpha(opacity=0);
					cursor: pointer;
					
					&:nth-child(3) {
						width: 130px;
					}
					
					&:hover + label {
						color: $red;
					}

					&:checked + label {
					    color: $red;
						z-index: 6;
					}
					
					&.tab-selector-1:checked ~ .content .content-1,
					&.tab-selector-2:checked ~ .content .content-2 {
						z-index: 100;
					    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
					    filter: alpha(opacity=100);
						// padding: 72px 0;
					    opacity: 1;
					    height: 100%;
					    transition: all ease-out 0.2s 0.1s;
					}

				}

				label {
					color: $white;
					font-size: 16px;
					line-height: 66px;
					height: 66px;
					position: relative;
					padding: 0 30px;
				    float: left;
					// display: block;
					letter-spacing: 1px;
					font-weight: 700;
					text-align: center;
					
					&:first-of-type {
					    z-index: 4;
					}
				}		

				.tab-label-2 {
				    z-index: 3;
				}				

				.tab-menu {
					// display: inline-block;
					
					&#tab-2{
						left: 300px;
					}
				}

				.content {
					position: relative;
					z-index: 5;

					&:before { 
					   content: "";
					   display: block; 
					   clear: both;
					}

					& > div {
						padding-top: 72px;
						// height: 0;
						// z-index: 1;
					 //    opacity: 0;
					 //    padding: 0;
					 //    transition: all linear 0.1s;
					 //    overflow: hidden;
					}
				}
				
				.top {
					display: grid;
					grid-template-columns: repeat(2,1fr);
					
					h2 {
						padding-right: 160px;
						align-self: center;
					}
					
					.ficha {
						grid-column: 1 / 2;
						grid-row: 2 / 3;
						align-self: center;
					}
					p {
						grid-column: 2 / 3;
						grid-row: 1 / 2;
						align-self: center;
						
						strong {
							display: block;
						}
					}
					.cotizacion {
						grid-column: 2 / 3;
						grid-row: 2 / 3;
						align-self: center;
						margin: 0;
						width: 320px;
					}
				}

				.especificaciones {

					.content-body {
						display: flex;
						flex-wrap: wrap;
						
						.item {
							flex: 50% 0 0;
							
							&:nth-child(1), &:nth-child(4) {
								flex: 100% 0 0;	

								.items {
									columns: 2;
									column-gap: 30px;
									padding: 0 60px;
									// display: flex;
									// flex-wrap: wrap;
									
									// li {
									// 	flex: 50% 0 0;
									// }
								}
							}
						}
					}
				}
				
				.versiones {

					.content-body {
						padding: 0 30px;
					}
					
					.item-model {
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						padding: 0 30px;
						
						img {
							align-self: center;
						}

						.block {
							margin: 0;
							padding: 30px 0 0 20px;
							align-self: center;
							
							.modelo {
								width: 100%;
							}
						}

						.price {
							font-size: 32px;
							padding: 0;
						}
						
						.cotizacion {
							margin: 0;
							font-size: 13px;
						}
					}
				}
			}
		}
	}
	@include breakpoint (laptop) {

		&_home {		
			
			.top {
				overflow: hidden;
				flex: 30% 0 0;
				
				h2 {
					font-size: 42px;
					line-height: 1;
					margin-bottom: 16px;
					position: relative;
					
					&:before {
						content: "";
						background: $red;
						height: 3px;
						width: 60px;
						position: absolute;
						top: 20px;
						right: -90px;
					}					
				}
			}

			.menu {
				width: 890px;
			}
		}

		.content-wrap {

			.banner {

				&_tagline {
					width: 50%;
					padding-top: 24px;
					
					.logo-jac {
						width: auto;
						height: 42px;
					}

					p {
						font-size: 22px;
					}
				}
			}
		}		

		.tabs-style-linemove {
			
			.tabs-content {

				& > input {
					left: 60px;
					
					&:nth-child(3) {
						width: 130px;
						margin-left: 30px;
					}
				}

				label:first-of-type {
					padding-left: 60px;
				}

				.top { 
					padding: 0 60px;
					
					h2 {
						font-size: 42px;
					}
				}
				
				.content-body {

					.subtitle {
						padding: 10px 60px;
					}

					.item {

						.items {
							padding-left: 62px;
						}
						
						&:first-child { 
						
							.items {
								padding: 0px 60px;	
							}
						}
						&:nth-child(3) { 
							
							.subtitle {
								padding-left: 0;
							}
							.items {
								margin-left: 0;	
								padding-left: 16px;
							}
						}
					}
				}	

				.versiones {

					.item-model {
						// display: grid;
						// grid-template-columns: repeat(2, 1fr);
						// padding: 0 30px;
						
						// img {
						// 	align-self: center;
						// }

						// .block {
						// 	margin: 0;
						// 	padding: 30px 0 0 20px;
						// 	align-self: center;
							
						// 	.modelo {
						// 		width: 100%;
						// 	}
						// }

						// .price {
						// 	font-size: 32px;
						// 	padding: 0;
						// }
						
						.cotizacion {
							font-size: 16px;
						}
					}
				}
			}
		}
	}
	@include breakpoint (desktop) {

		&_home {	
			padding-top: 16px;	
			
			.top {
				overflow: hidden;
				// flex: 30% 0 0;
				
				h2 {
					font-size: 56px;
					
					&:before {
						top: 26px;
					}
				}
			}
			.menu {
				width: 930px;
			}
		}
	}
	@include breakpoint(bigdesktop) {		
		// padding: 100px 10vw;

		&_home {	
			padding-left: 60px;
			
			.top {
				// overflow: hidden;
				// flex: 30% 0 0;
				
				h2 {
					// font-size: 56px;
					
					// &:before {
					// 	top: 26px;
					// }
				}
			}
			.menu {
				// width: 1230px;
			}
		}

		.content-wrap {

			.banner {

				&_tagline {
					// width: 50%;
					
					.logo-jac {
						// width: auto;
						// height: 42px;
					}

					p {
						font-size: 28px;
					}
				}
			}
		}
	}	
}