.design {

	.top {
		background: $black;
		color: $white;	
		padding: 72px 30px 72px 60px; 
		
		h2 {
			font-size: 30px;
			margin-bottom: 16px;
			position: relative;
					
			&:before {
				content: "";
				background: $red;
				height: 3px;
				width: 60px;
				position: absolute;
				top: 22px;
				left: -90px;
			}
		}

		.more {
			display: flex;
			margin: 16px 0 0 0;
			
			span {
				color: $red;
				font-weight: 700;
				line-height: 26px;
				margin-right: 18px;
			}

			.arrow {
				width: 35px;
				height: 25px;
				fill: $red;
				
				&:hover {
					fill: $white;
				}
			}
		}
	}

	.gallery {
		
		.slide_gallery {
			
			.swiper-wrapper {
				display: block;
			}
		}

		.swiper-slide {
			height: 280px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			padding: 20px;
			color: white;
			font-size: 18px;
		}
	}		

	@include breakpoint (tablet) {
		padding: 30px 0;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		overflow: hidden;
		
		.gallery {
			width: calc(100% + 60px);
			grid-column: 2 / 4;
			grid-row: 1 / 2;
			align-self: center;
			z-index: 2;
			display: block;
			margin-left: -60px;
			box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.45);
			
			h2 {
				font-size: 38px;
			}
			
			.slide_gallery {
				
				.swiper-wrapper {
					display: flex;
				}
			}
		}

		.top {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
			min-height: 340px;
			width: 340px;
			border-radius: 0 3px 3px 0;
			box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.45);
		}		
	}	

	@include breakpoint (laptop) {
		padding: 72px 0;

		.top {
			padding: 80px;
			min-height: 480px;
			width: auto;
			
			h2 {
				font-size: 46px;
		
				&:before {
					width: 80px;
					top: 30px;
					left: -120px;
				}				
			}
		}

		.gallery {

			.swiper-slide {
				height: 380px;
			}			
		}
	}	

	@include breakpoint (bigdesktop) {
		padding: 100px 0;
	}
}