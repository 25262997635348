.contact {
	background: $white;

	&_title {
		color: $white; 
		font-size: 32px;
		padding: 48px;
		text-align: center;
	}

	form {
		padding: 0 48px 48px;
		
		input,textarea {
			border-color: $grey;
			color: $white;

			&::placeholder {
				color: $grey;
			}	

			&:focus {
				border-color: $red;
				
				&::placeholder {
					color: $white;
				}
			}			
		}
	}

	.interna & {
		background: $white-bone;
		
	}

	@include breakpoint(phablet) {

		form {
			max-width: 70%;
			margin: 0 auto;
		}
	}
	@include breakpoint(tablet) {
		padding: 0 60px 40px;
		
		form {
			width: 100%;
			max-width: none;
			padding: 0;
		    display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 26px;
			
			* {margin: 0;} 

			input {        	
				grid-column: 1 / 2;
				padding: 9px;
				height: 44px;
			}
			select {
				grid-column: 2 / 3;	
				grid-row: 1 / 2;
			}
			textarea {        
				grid-column: 2 / 3;	
				grid-row: 1 / 3;
				padding: 9px;
			}
			.button__rayen {
				grid-column: 2 / 3;	
				grid-row: 3 / 4;
			}
		}
	}
	@include breakpoint(laptop) {
		
		form {
			width: 800px;
		}				
	}
	@include breakpoint(desktop) {
		padding: 0 0 40px;		
		
	}
	@include breakpoint(bigdesktop) {
		padding: 0 0 80px;		

		form {
			width: 1100px;
		}			
	}
}