.hero {
	height: 100vh;
	overflow: hidden;
	
	.swiper-container {
		height: 100%;
		
		.swiper-slide {
			position: relative;
			overflow: hidden;
		}

		.swiper-pagination {
			left: 0;
			width: 100%;
			bottom: 30px;
			display: flex;
			justify-content: center;
			
			.swiper-pagination-bullet {
				width: 26px;
				height: 26px;
				margin: 0 6px;
				background: $white;
				opacity: 1;
				
				&.swiper-pagination-bullet-active {
					background: $red;
				}
			}
		}		
	}

	img {
		width: 100%;
	    height: 100%;
	    object-fit: cover;
		object-position: center bottom;
	    position: absolute;
	    z-index: 1;
	}

	&.interna {
		height: calc(100vh - 72px);
		position: relative;
		background: #EEEFE9;
		
		h1 {
			font-size: 36px;
			color: $red;
			line-height: 1;
			margin-bottom: 0;
		}

		h2 {
			font-size: 36px;
			color: $white;
		}

		.hero_tagline {
			top: auto;
			bottom: 0;
			transform: initial;
		}

		&.gracias {
			
			h2 {
				font-size: 36px;
			}
		}

		&.contacto {
			
			h1 {
				color: $black;
				
				span {
					color: $red;	
				}
			}
		}

		&.empresa {
			display: block;
			padding: 0;
			
			&:after {
				content: none;
			}
			
			h1 {
				color: $white;
			}
			p {
				color: $white;	
			}
		}

		&.modelo {
			padding: 72px 0 0 0;
			height: auto;
			
			picture {
				overflow: hidden;
				animation: hideHero .8s 3s forwards cubic-bezier(0.04, 0.91, 0.57, 1);
			}
			
			&:after {
				content: none;
			}
			
			h1 {
				color: $white;
			}
			p {
				color: $white;	
			}

			img {
				position: relative;
				// height: auto;
				// height: calc(100vh - 270px);
				// object-fit: contain;
			}
		}
	}

	&_tagline {
		padding: 0 30px 40px;
		// width: 80%;
		position: absolute;
		// top: 60%;
		top: 74%;
		left: 0;
		transform: translateY(-50%);
		z-index: 2;
		
		&:before {
			content: "";
			background: $red;
			height: 3px;
			width: 100px;
			position: absolute;
			top: 12px;
			left: -80px;
			
			.wline & {
				content: none;
			}
		}
		
		.logo-jac-hero {
			width: 190px;
			height: 28px;
			margin-bottom: 22px;
		}

		h1 {
			// font-size: 42px;
			// line-height: 52px;
			font-size: 32px;
			line-height: 42px;
			margin-bottom: 22px;
			// margin-bottom: 52px;
		}

		.button__rayen {
			border-color: $red;
			color: $white;
		    background: $red;
		    border-radius: 30px;		
		    
			& > span {
			    color: $white;
			}

			&:before {
				background: $white;		    
			}
		}
	}

	.gracias & {
		min-height: 100vh;
		display: flex;
        flex-direction: column;
        justify-content: center;
		padding: 0 20px;
	}	

	@include breakpoint(phablet) {

		&.interna {
		
			h1 {
				font-size: 56px;
			}

			h2 {
				font-size: 56px;
			}	

			img {
				object-position: center top;
			}	

			.hero_tagline {
				
				&:before {
					top: 24px;
				}
			}
		}
	
		&_tagline {
			width: 80%;
			
			h1 {
				font-size: 46px;
				line-height: 56px;
			}
		}

		.gracias & {
	        justify-content: center;
		}	

	}
	@include breakpoint(tablet) {
		
		h1 {
			background: $black;
			color: #fff;
			padding: 0 22px 0 0;
			position: relative; 
			border-radius: 0 30px 30px 0;

			&:before {
				content: '';
				width: 100%;
				left: -100%;
				height: 100%;
				background-color: $black;
				position: absolute;
			}
		}

		&_tagline {
			width: 50%;
			padding: 0 60px 40px;

			.button__rayen {
				width: 70%;
				margin: 0;
			}

			&.white {
				color: $white;
			}
		}			
		
		&.interna {
			height: 62vh;

			.hero_tagline {
				width: auto;
			}
		
			h1 {
				background: none;
				// color: #fff;
				padding: inherit;
				position: initial;  
				border-radius: 0;
	
				&:before {
					content: none;
				}
			}

			img {
				height: calc(100vh - 72px);
			}	

			&.modelo {
				padding: 0;
			
				img {
					height: calc(100vh - 360px);
					object-fit: contain;
					object-position: center;
				}		
			}
		}
	}
	@include breakpoint(laptop) {

		h1 {
			font-size: 56px;
			line-height: 66px;
		}	
		
		&_tagline {
			
			&:before {
				left: -70px;
			}	
		}

		&.interna {

			.hero_tagline {
				width: auto;
				padding-left: 86px;
				
				&:before {
					top: 30px;
					left: -40px;
				}
			}

			&.empresa {
				
				p {
					font-size: 32px;	
				}
			}
		}	
	}
	@include breakpoint(desktop) {

		&.interna {
		
			h1, h2 {
				font-size: 86px;
			}
		}	
	}
	@include breakpoint(bigdesktop) {
		
		&_tagline {
			width: 35%;
		}		    
	}
}

.proximamente {
	padding: 72px 30px;
	
	h3 {
		font-size: 24px;
		text-align: center;
	}

	@include breakpoint(laptop) {
		
		h3 {
			font-size: 32px;
		}
	}
	@include breakpoint(laptop) {
		padding: 100px 0;
		
	}
}

@keyframes hideHero {
	0%   { max-height: 360px; }
	100% { max-height: 0px; }
}